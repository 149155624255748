export const priorityRef = [
    {
      title: "No Priority",
      style: "0",
      backgroundStyle:""
    },
    {
      title: "Urgent",
      style: "#500000",
      backgroundStyle: "#dfd4d4"
    },
    {
      title: "High",
      style: '#ad6014',
      backgroundStyle: "#dccfc2"
    },
    {
      title: "Normal",
      style: '#a08700',
      backgroundStyle: "#dedac5"
    },
    {
      title:"Low",
      style: '#1d5100',
      backgroundStyle: "#c3ccbf"
    },
    {
      title: "Lowest",
      style: 'rgba(0,0,0,0.4)',
      backgroundStyle: "#d9d9d9"
    }
  ]



export const activityRef = {
	new_message: {
		title: "New Message",
		icon: "chat_bubble_icon"
	},
	new_order: {
		title: "New Order Created",
    icon: "add_box",
    color: "#006400"
	},
	order_completed: {
		title: "Order Completed",
    icon: 'check_box',
    color: "#324b6f"
	},
	order_confirmed: {
		title: "Order Confirmed",
    icon: 'thumb_up',
    // color: "#888"
	},
	order_modified: {
		title: "Order Modified",
		icon: 'build'
	},
}
