import React from 'react';
import { Link } from 'react-router-dom';
import AddOrderForm from './addOrderForm'
import OrderHistory from './orderHistory';

export default class OrderPage extends React.Component {
  state = {
    loading: false,

  }

  componentDidMount() {
    const {
      orders,
      orderId,
      fetchOrders,
      // history,
      // user
    } = this.props;
    this.setState({ loading: false });
    const foundOrder = orders.find(order => order._id === orderId);
    const newOrder = orderId === "new"

    if (!foundOrder && !newOrder) {
      this.setState({ loading: true });
      fetchOrders(() => {
        this.setState({ loading: false });
      })
    }
  }

  render() {
    const {
      orders,
      orderId,
      history,
      user
    } = this.props;

    const { loading } = this.state;

    const foundOrder = orders.find(order => order._id === orderId);
    const pendingOrder = this.props.pendingOrderInfo ? {
      ...(this.props.pendingOrderInfo),
      status: "active",
      pending: true,
      priority: 1,
    } : undefined
    // var pendingOrder;
    // console.log(this.props.pendingOrderInfo)
    // pendingOrder = this.props.pendingOrderInfo
    // if (this.props.pendingOrderInfo) {
    //   pendingOrder = Object.assign({}, this.props.pendingOrderInfo)
    // }
    const newOrder = orderId === "new"
    if (!foundOrder && !newOrder) {
      return (
        <div
          style={{
            backgroundColor: 'white',
            padding: "20px",
            margin: '20px',
            boxShadow: '2px 2px 2px grey',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          {loading ? (
            "Loading"
          ): ([
            <div key="txt-orderpage">Could Not Find Order</div>,
            <Link
              key="link-orderpage"
              to="/"
              style={{
                color: "#324b6f",
                padding: '20px'
              }}
            >
              <b>Go Back</b>
            </Link>

            ])}
        </div>
      )
    }
    return (
      <div
        className="grid-x"
      >
        <div className={`cell large-${newOrder ? "12" : "8"} medium-12 small-12`}>
          <AddOrderForm
            order={foundOrder || pendingOrder} // can be undefined
            user={user}
            history={history}
          />
        </div>
        {!newOrder && 
          <div className="cell large-4 medium-12 small-12">
            <OrderHistory
              order={foundOrder}
            />
          </div>
        }
      </div>
    )
  }
}