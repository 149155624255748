import React from 'react';
import { format, startOfDay, isSameDay } from 'date-fns'
import { activityRef } from '../refs/refs'
import { Link } from 'react-router-dom'

export default class ActivitiesList extends React.Component {
	state = {
		organizedDates: [],
	}
	componentDidMount() {
		const { socket } = this.props;

		socket.on("new_activity", new_activity => {
			console.log("New Activity");
			this.addActivityToOrganizedDates(new_activity);
		})
		fetch('/api/v1/activity')
		  .then(response => response.json())
			.then(data => {
				if (data) {
          const organizedDates = this.organizeDates(data)
					this.setState({
						organizedDates
					})
				} else {
					console.log("Could not get activity");
				}
			})
  }
  
  organizeDates(activities) {
    const dates = [];
    activities.forEach(activity => {
      if (dates.length === 0) {
        dates.push({
          day: startOfDay(activity.created),
          activities: [activity]
        })
      } else {
        let hasHome = false;
        dates.forEach((date, i) => {
          if (isSameDay(activity.created, date.day)) {
            dates[i].activities.push(activity)
            hasHome = true;
          }
        })
        if (!hasHome) {
          dates.push({
            day: startOfDay(activity.created),
            activities: [activity]
          })
        }
      }
    })
    const datesWithNumOrders = dates.map(date => {
      return ({
        ...date,
        numCompletedOrders: date.activities.filter(a => a.activityType === 'order_completed').length
      })
    })
    return datesWithNumOrders;
  }
  addActivityToOrganizedDates(newActivity) {
    const { organizedDates: dates } = this.state;
    let hasHome = false;

    dates.forEach((date, i) => {
      if (isSameDay(newActivity.created, date.day)) {
        dates[i].activities.unshift(newActivity)
        hasHome = true;
      }
    })
    if (!hasHome) {
      dates.push({
        day: startOfDay(newActivity.created),
        activities: [newActivity],
        numCompletedOrders: 0
      })
    }
    this.setState({ organizedDates: dates })
  }
	render() {
    const { organizedDates } = this.state;
    
    return (
      <div
				style={{
					height: '800px',
					overflow: 'scroll'
				}}
			>
				<div
					className="app-item"
					style={{
						backgroundColor: "#333",
            color: 'white',
            display: 'flex',
            alignItems: 'center'
					}}
				>
          <i className="material-icons">timer</i>
					<b>Recent Activity</b>
				</div>
        <div
          style={{
            // backgroundColor
          }}
          className="app-item"
        >
          {organizedDates.map(date => {
            return (
              <div
                key={date.day}
                style={{
                  marginBottom : '4px'
                }}
              >
                <div
                  style={{
                    fontWeight: 600,
                    fontSize: '100%',
                    borderBottom: 'solid rgba(0,0,0,0.2) 1px',
                    marginBottom : '2px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}
                >
                  <div>
                    {format(date.day, "M/D/YY - ddd")}
                  </div>
                  <div
                    style={{
                      fontWeight: '400',
                      fontSize: '85%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      color: "#233876"
                    }}
                  >
                    <b style={{ paddingRight: '4px'}}>{date.numCompletedOrders}</b> Orders Shipped
                  </div>
                </div>
                {date.activities.map(activity => (
                  <Link
                    to={`/orders/${activity.objId}`}
                    key={activity._id}
                    style={{
                      display: 'flex',
                      color: "#333",
                      alignItems: 'flex-start',
                      paddingBottom: '5px',
                      marginBottom: '5px',
                      borderBottom: 'solid rgba(0,0,0,0.1) 1px'
                    }}
                  >
                      <i
                        className="material-icons"
                        style={{
                          fontSize: '100%',
                          margin: '3px',
                          color: (activityRef[activity.activityType] && activityRef[activity.activityType].color) || "",
                      }}>
                        {activityRef[activity.activityType] && activityRef[activity.activityType].icon}
                      </i>
                      <div
                        style={{
                          fontSize: "85%"
                        }}
                      >
                        <div
                          style={{
                            fontWeight: '600',
                            color: (activityRef[activity.activityType] && activityRef[activity.activityType].color) || ""
                          }}
                        >
                          {activityRef[activity.activityType] && activityRef[activity.activityType].title}
                          <span
                            style={{
                              fontSize: '90%',
                              fontWeight: '500',
                              paddingLeft: '10px'
                            }}
                          >
                            {format(activity.created, "HH:mm ")}
                          </span>
                        </div>
                        <div
                          style={{
                            fontSize: '802',
                            whiteSpace: 'pre-line'
                          }}
                        >
                          {activity.body}
                        </div>    
                      </div>
                  </Link>
                ))}
              </div>
            )
          })
          }
        </div>
      </div>
    )
	}
}
