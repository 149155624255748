import React from 'react';
import { format } from 'date-fns';
import OrdersList from './ordersList';
import CheckIns from './checkIns';
import { Route } from 'react-router-dom';
import ActivitiesList from './activitiesList';
import OrderPage from './orderPage';
import AnnouncementForm from './postForm';
import PostsList from './postsList'
// import AddOrderForm from './addOrderForm'
// import OrderHistory from './orderHistory';

export default class AdminDashboard extends React.Component {
  state = {
    pendingOrderInfo: null,
  }
  updateAdminDashboardState = newState => this.setState(newState)
  
  render() {
		const {
			orders,
			user,
			socket,
      updateUser,
      ordersLoading,
      fetchOrders,
      posts,
		} = this.props;
    return (
      <div
        style={{
        }}
        className="grid-x"
      >
        <div
          style={{}}
          className="cell large-3"
          // className="cell show-for-large show-for-xlarge show-for-xxlarge large-3 "
        >
          <div
            className="app-item"
            style={{
              // backgroundColor: '#333',
              // color: 'white'
              color: "#333"
            }}
          >
            <div style={{  fontSize: '150%', fontWeight: '700', paddingBottom: '5px', display: 'flex', alignItems: 'center' }}>
              <i className="material-icons">date_range</i>
              {format(new Date(), "MMM Do YYYY")}
            </div>
            <div>Total # Active Orders: {orders.filter(order => order.status === "active").length}</div>
          </div>
          <div
            className="show-for-large show-for-xlarge show-for-xxlarge"
          >
            {user && user.role === "admin" &&
              <AnnouncementForm />
            }
            <ActivitiesList
              socket={socket}
              user={user}
            />
          </div>
        </div>
        
        <div
          style={{
            // width: "75%",
          }}
          className="cell large-9 medium-12 small-12"
        >
          <div className="grid-x">
            <div className="cell">
              <CheckIns
                user={user}
                socket={socket}
                view="admin"
                // view={(user || {}).role}
              />
            </div>
          </div>
          <PostsList
            posts={posts}
            user={user}
          />
          <Route
            exact
            path="/"
            render={() => (
              <OrdersList
                orders={orders}
                user={user}
                socket={socket}
                updateUser={updateUser}
                ordersLoading={ordersLoading}
                fetchOrders={fetchOrders}
                updateAdminDashboardState={this.updateAdminDashboardState}
              />
            )}
          />
          <Route
            path="/orders/:orderId"
            render={({ history, match: { params: { orderId } }}) => {
              return (
                <OrderPage
                  orders={orders}
                  orderId={orderId}
                  history={history}
                  user={user}
                  fetchOrders={fetchOrders}
                  pendingOrderInfo={this.state.pendingOrderInfo}
                />
              )
            }}
          />
        </div>
      </div>
    );
  }
}
