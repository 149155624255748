import React from 'react';
// import Loading from "./components/common/loading";
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { format } from 'date-fns'
import { priorityRef } from './refs/refs';
import ReactToolTip from 'react-tooltip'
import CheckIns from './components/checkIns';


export default class Dashboard extends React.Component {
  state = {
    // orders: [],
    // loading: false,
    // orderToUpdate: "",
    // checkIns: [],
    // user: null,
    // time: new Date(),
    // messages: [],
    // unreadMsgExists: false,
    // unreadMsg: "",
    // posts: [],
    // pinnedPosts: [],
  }

	componentDidMount() {
		// const { socket } = this.props;
		// socket.on("new_message", msg => {
		// 	console.log("New Message");
		// 	this.setState({ messages: [msg, ...this.state.messages] });
		// })
		// this.fetchCheckIns();
    // this.fetchOrders(); // should be true, false for dev
    // this.fetchMsgPending();

    // this.props.socket.on("order_update", this.updateOrder);
    // this.props.socket.on("order_new", this.addNewOrder);
    // this.props.socket.on("remove_order", this.removeOrder);
    // this.props.socket.on("checkin_update", this.updateCheckin);

    // this.props.socket.on("unread_msg", this.updateUnreadMsg);
    // this.props.socket.on('pinned_post', this.updatePinnedPost);

    // this.props.socket.on("post_create", this.createPost);    
    // this.props.socket.on("post_destroy", this.destroyPost);
    // this.props.socket.on("post_update", this.updatePost);

    // this.fetchPosts();

		// this.interval = setInterval(() => {
		// 	this.fetchCheckIns();
    //   this.fetchOrders(); // should be true, false for dev
    //   this.setState({ time: new Date() })
    // }, 30000);

    // this.checkLogin() 
    // this.fetchPinnedPosts()  
  }
  // createPost = (newPost) => {
  //   console.log("creating post")
  //   const { posts } = this.state;
  //   const updatedPosts = [newPost, ...posts]
  //   this.setState({ posts: updatedPosts })
  //   new Audio('/ting.mp3').play();

  // }

  // updatePost = updatedPost => {
  //   const { posts } = this.state;
  //   const updatedPosts = posts.map(post =>
  //     post._id === updatedPost._id ? updatedPost : post
  //   )
  //   this.setState({ posts: updatedPosts })
  // }
  // destroyPost = (removedPost) => {
  //   const { posts } = this.state;
  //   const filteredPosts = posts.filter(post => post._id !== removedPost._id);
  //   this.setState({ posts: filteredPosts })

  // }
  // ackFetch = () => {
  //   fetch('/api/v1/msgPending', {
  //     method: "PUT",
  //   }).then(response => {
  //     if (response.ok) {
  //       response.json().then(data => {
  //         this.setState({
  //           unreadMsgExists: false,
  //           unreadMsg: ""
  //         })
  //       })
  //     } else {
  //       console.log("Error Occurred")
  //     }
  //   }).catch(error => {
  //     console.log(error)
  //   })
  // }

  // updatePinnedPost = (post) => {
  //   let newPosts = this.state.posts;
  //   if (this.state.posts.find(p => p.id === post.id)) {
  //     newPosts = newPosts.map(p => {
  //       if (post.id === p.id) {
  //         return post;
  //       } else {
  //         return p;
  //       }
  //     })
  //   } else {
  //     newPosts = [post, ...newPosts];
  //   }
  //   this.setState({
  //     pinnedPosts: newPosts.filter(p => p.is_pinned)
  //   })
  // }

  // confirmPost = (post) => {
  //   console.log("ASD")
  //   fetch('/api/v1/posts', {
  //     method: "PUT",
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({
  //       _id: post._id,
  //       changes: {
  //         confirmed: true,
  //       }
  //     })
  //   })
  // }

  // fetchPinnedPosts = () => {
  //   fetch('/api/v1/pinnedPosts')
  //     .then(response => {
  //       if( response.ok) {
  //         response.json().then(data => {
  //           this.setState({ pinnedPosts: data })
  //         })
  //       }
  //     }).catch(error => {
  //       console.log(error)
  //     })
   
  // }
  // fetchPosts = () => {
  //   fetch('/api/v1/posts')
  //   .then(response => {
  //     if( response.ok) {
  //       response.json().then(data => {
  //         this.setState({ posts: data })
  //       })
  //     }
  //   }).catch(error => {
  //     console.log(error)
  //   })
  // }

  // fetchMsgPending = () => {
  //   fetch('/api/v1/msgPending').then(response => response.json())
  //   .then(data => {
  //     // console.log(data)
  //     if (data) {
  //       this.setState({
  //         unreadMsgExists: data.unreadMsgExists,
  //         unreadMsg: data.unreadMsg
  //       })
  //     }
  //   })
  // }

  // updateUnreadMsg = (unreadMsg) => {
  //   if (unreadMsg.unreadMsgExists) {
  //     new Audio('/ting.mp3').play();
  //   }
  //   console.log(unreadMsg)
  //   this.setState({
  //     unreadMsgExists: unreadMsg.unreadMsgExists,
  //     unreadMsg: unreadMsg.unreadMsg
  //   })
  // }

  // componentWillUnmount() {
  //   this.props.socket.removeListener("order_update");
  //   this.props.socket.removeListener("order_new");
  //   this.props.socket.removeListener("remove_order");
  //   this.props.socket.removeListener("checkin_update");
  //   this.props.socket.removeListener("unread_msg");
  //   this.props.socket.removeListener("pinned_post");
  //   if (this.interval) {
  //     clearInterval(this.interval)
	// 	}
	// 	this.props.socket && this.props.socket.removeListener('new_message');
  // }

  // updateOrder = (updatedOrder) => {
  //   const { orders } = this.state;
  //   const updatedOrders = orders.map(order => {
  //     if (order._id === updatedOrder._id) {
        
  //       // Swal.fire({
  //       //   title: `${order.orderNumber} for ${order.shippingPersonCompany} updated`,
  //       //   text: updateOrderText,
  //       //   icon: 'warning',
  //       //   // showCancelButton: true,
  //       //   confirmButtonColor: '#3085d6',
  //       //   // cancelButtonColor: '#d33',
  //       //   confirmButtonText: 'Acknowledge'
  //       // }).then((result) => {
  //       //   if (result.value) {
  //       //     //console.log("done")
  //       //   }
  //       // })
  //       return updatedOrder
  //     } else {
  //       return order
  //     }
  //   })
  //   this.setState({ orders: updatedOrders })
  // }

  // addNewOrder = (newOrder) => {
  //   const { orders } = this.state;
  //   const updatedOrders = [newOrder, ...orders]
  //   this.setState({ orders: updatedOrders })
  //   // new Audio('/ding.mp3').play();
  //   new Audio('/cheer.mp3').play();
  //   Swal.fire({
  //     position: 'top',
  //     type: 'success',
  //     title: 'New Order Added',
  //     showConfirmButton: false,
  //     timer: 2000
  //   })
  // }
  
  // removeOrder = (removedOrder) => {
  //   const { orders } = this.state;
  //   const filteredOrders = orders.filter(order => order._id !== removedOrder._id);
  //   this.setState({ orders: filteredOrders })
  // }

  // updateCheckin = (updatedCheckin) => {
  //   const { checkIns } = this.state;
  //   const newCheckins = checkIns.map(checkIn => 
  //     checkIn._id === updatedCheckin._id ? updatedCheckin : checkIn
  //   )
  //   this.setState({ checkIns : newCheckins });
  // }

  // checkLogin() {
  //   if (localStorage.token) {
  //     fetch(`/api/v1/token?t=${localStorage.token}`)
  //       .then(response => response.json())
  //       .then(data => {
  //         if (data && data._id) {
  //           this.setState({ user: data });
  //         }
  //       })
  //   }  else {
  //     this.setState({ authorized: false })
  //     console.log("NO token found")  
  //   }
  // }


  // fetchCheckIns = () => {
  //   fetch('/api/v1/checkIns')
  //     .then(response => response.json())
  //     .then(data => {
  //       if (data) {
  //         this.setState({ checkIns: data });
  //       } else {
  //         console.log("Error pulling check ins")
  //       }
  //     })
	// }

  // fetchOrders = (withUpdate, cb) => {
  //   fetch(withUpdate ? '/api/v1/updatedOrders' : '/api/v1/orders?status=active')
  //     .then(response => response.json())
  //     .then(data => {
	// 			if (data) {
	// 				if (this.state.orders.length > 0 && data.length > this.state.orders.length) {
  //           new Audio('/portal.mp3').play();
  //         }
  //         this.setState({ orders: data })
  //         cb && cb(null, true)
  //       } else {
  //         cb && cb(true)
  //       }
  //     })
  // }

  goFullScreen() {
    var elem = document.getElementById("dashboard");
    if (elem) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      }
    } else {
      console.warn("Could not go fullscreen")
    }
    
  }
  // changeDashboardState = newState => this.setState(newState)

  render() {
    // const {
    //   checkIns,
    //   loading,
    //   orders,
    //   user,
    //   unreadMsg,
    //   unreadMsgExists

    // } = this.state;
    
    const {
      orders,
      posts,
      user,
      socket,
      time
    } = this.props;

    const currentOrders = orders.filter(order => order.status === 'active').sort((a, b) => a.priority > b.priority ? 1 : -1 || a.created > b.created ? -1 : 1 )
    // const orderedCheckins = [...checkIns.filter(c=> c.active), ...checkIns.filter(c=>!c.active)]

    return (
      <div>
        {/* {loading && Loading} */}
        <div
          className="grid-x"
        >
          <div
            className="cell "
          >
            <div className="grid-x">
              <div
                className="cell large-6 medium-12 small-12"
                style={{
                  padding: '10px',
                  backgroundColor: "#333",
                  borderRight: "solid grey 1px",
                  display : "flex",
                  justifyContent: 'space-around',
                  fontSize: "100%",
                  // flex: 1,
                  alignItems: 'center',
                  fontWeight: '800',
                  color: "white",
                  // flexWrap: 'wrap'
                }}
              >
                <div style={{ fontSize: '120%'}}>
                  {format(time, "MMM D YYYY - HH:mm:ss ")}
                </div>
                <div style={{ fontSize: '120%'}}>
                {currentOrders.length} Total Orders
                </div>
              <div
                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                onClick={() => this.goFullScreen()}
              >
                <i className="material-icons">fullscreen</i>
                <span>Fullscreen</span>
              </div>
            <div
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            > 
              <i className="material-icons">edit</i>
              <Link
                to="/"
                style={{
                  color: 'white',
                  textDecoration: 'none',
                  
                }}
              >
                Manage Orders
              </Link>
            </div>
          </div>

          <div
            className="cell large-6 medium-12 small-12"
            style={{
              padding: '7px',
              paddingTop: '12px',
              backgroundColor: '#333',
              border: 'solid rgba(0,0,0,0.1) 1px',
              boxShadow: 'rga(0,0,0,0.2) 2px 2px 3px'
              // flex: 1
            }}
          >
            {/* CHECK INS HERE */}
            
            <CheckIns
              socket={socket}
              user={user}
            />
          </div>
        </div>
      </div>
        <div className="cell">
          <div className="grid-x">
            {posts.map(post => {
              return (
                <div
                  className="cell large-2 medium-4 small-6"
                  key={post.id}
                  style={{
                    padding: '3px'
                  }}
                >
                  <div
                    className="app-item"
                    style={{
                      margin: '5px',
                      width: '100%',
                      borderRadius: '4px',
                      boxShadow: '0px 2px 2px 2px rgba(0,0,0,0.2)',
                      height: '100%'      ,
                      border: "solid #324b6f 2px",
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between'
                    }}
                  >
                    <div>
                      <h3
                        style={{
                        }}
                      >
                        Pinned Message
                      </h3>
                      <div
                        style={{
                        }}
                      >
                        {post.message || post.body}
                      </div>
                      {post.confirmationRequired &&  !post.confirmed &&
                        <div className="button" style={{ margin: '10px'}} onClick={() => this.confirmPost(post)}>
                          Confirm Post
                        </div>
                      }
                      {post.confirmationRequired && post.confirmed &&
                        <div
                          style={{
                            margin: '10px',
                            display: 'flex',
                            justifyContent: 'center'
                          }}
                        >
                          <b>Confirmed</b>
                        </div>
                      }
                    </div>

                    <div
                      style={{
                        color: "#444"
                        // fontWeight: '600'
                      }}
                    >
                      {format(post.create_at || post.created, "M/D/YY - HH:mm")}
                    </div>
                 </div>
              </div>
              )
            })}
            {currentOrders.map(order => (
              <div
                className="cell large-2 medium-4 small-6"
                key={order._id}
                style={{
                  padding: '3px'
                }}
              >
                <OrderItem
                  key={order._id}
                  order={order}
                  changeDashboardState={this.changeDashboardState}
                  user={user}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    )
  }

}

class OrderItem extends React.Component {

  onCardClick = () => {
    const { order } = this.props;
    Swal.fire({
      text: `${order.orderNumber} for ${order.shippingPersonCompany}`,
      title: "Manage Order",
      confirmButtonColor: '#324b6f',
      cancelButtonColor: 'maroon',
      showCancelButton: true,
      confirmButtonText: 'Mark as FULFILLED',
      cancelButtonText: 'Mark a PROBLEM'
    }).then(result => {
      if (result.value) {
        this.finish("finished");
      } else {
        this.reportIssue()
        // mark a problem path
      }
    })
    // this.finish();

  }

  reportIssue() {
    Swal.fire({
      title: "Report Issue with Order",
      input: "textarea",
      showCancelButton: true,

    }).then(result => {
      console.log(result)
      if (result.value === "") {
        console.log("empyty response")

      } else if (result.value) {
        console.log("Confirmed");

        // fetch('/api/v1/orders', {
        //   'method': 'PUT',
        //   headers: {
        //     'Accept': 'application/json',
        //     'Content-Type': 'application/json'
        //   },
        //   body: JSON.stringify({
        //     _id: order._id,
        //     changes: {
        //       issueNoteText,
        //     }
        //   }),
        // }).then(response => response.json())
        //   .then(data => {
        //     new Audio('/swoosh.mp3').play();
        //     // fetchOrders(); // reget all again
        //     cb && cb(null, true)
        //   })

      } else {
        console.log("Cancelled")
      }
    })

  }

  finish(status, cb) {
    const { order } = this.props;
    const confirmed = status === "active" ? false : order.confirmed; 

    Swal.fire({
      title: 'Confirm Finishing Shipment',
      // text: "You won't be able to revert this!",
      // type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#324b6f',
      cancelButtonColor: 'maroon',
      confirmButtonText: 'Confirm'
    }).then((result) => {
      if (result.value) {
        fetch('/api/v1/orders', {
          'method': 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            _id: order._id,
            changes: {
              status,
              confirmed
            }
          }),
        }).then(response => response.json())
          .then(data => {
            new Audio('/swoosh.mp3').play();
            // fetchOrders(); // reget all again
            cb && cb(null, true)
          })
      }
    })
    
  }
  confirm() {
    const { order } = this.props;

    fetch('/api/v1/orders', {
      'method': 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        _id: order._id,
        changes: {
          confirmed: true,
        }
      }),
    }).then(response => response.json())
      .then(data => {
      })
  }
  render() {
    const {
      order,
      // user,
    } = this.props;
    const priorityColor = priorityRef[order.priority].style;
    const priorityColorBackground = priorityRef[order.priority].backgroundStyle;

    return (
      <div
        className="app-item dashboard-card"
        style={{
          margin: '5px',
          width: '100%',
          border: `solid ${priorityColor} 3px`,
          backgroundColor: priorityColorBackground,
          borderRadius: '4px',
          boxShadow: '0px 2px 2px 2px rgba(0,0,0,0.2)',
          height: '100%',
          display:'flex',
          flexDirection: 'column',
          justifyContent: "space-between" 
        }}
        onClick={() => {
          if (order.confirmed) {
            this.onCardClick()
          }
        }}
      >
        {!order.confirmed &&
          <div
            style={{
              backgroundColor: 'rgba(255,255,255,0.6)',
              position: 'absolute',
              height: '100%',
              width: '100%',
              left: "0px",
              top: "0px", 
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontWeight: '800'
            }}
          >
            <div className="button" onClick={() => this.confirm()}>
              Confirm New Order
            </div>
          </div>
        }
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
              <span
                style={{
                  fontSize: '18px',
                  backgroundColor: priorityRef[order.priority].style,
                  color: 'white',
                  padding: '3px',
                  borderRadius: '100%',
                  width: '35px',
                  height: '35px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
                data-tip
                data-for={order._id+"priority"}
              >
                {order.priority}
              </span>
              <ReactToolTip
                id={order._id+"priority"}
                effect="solid"
                place="bottom"
              >
                <b>{order.priority}</b> - {priorityRef[order.priority].title}
              </ReactToolTip>
            <span>
              <i
                className="material-icons"
                style={{fontSize: '250%', color: order.confirmed ? 'forestgreen' : 'grey'}}
                data-tip
                data-for={order._id}
              >
                {order.confirmed ? "check_circle" : "check_circle_outline"}
              </i>
                <ReactToolTip
                  id={order._id}
                  effect="solid"
                  place="bottom"
                >
                  {order.confirmed ? "Confirmed" : "Not Confirmed"}
                </ReactToolTip>
              <i
                className="material-icons"
                style={{fontSize: '250%', color: order.shippingPrinted ? 'forestgreen' : 'grey'}}
                data-tip
                data-for={order._id+"shipprint"}
              >
                {order.shippingPrinted ? "print" : "print_disabled"}
              </i>
              <ReactToolTip
                id={order._id+"shipprint"}
                effect="solid"
                place="bottom"
              >
                {order.shippingPrinted ? "Paperwork is Ready" : "Paperwork is not ready"}
              </ReactToolTip>
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'nowrap',
            }}
          >
            <div style={{  width: '100%', }} >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontSize: '100%',
                  fontWeight: '700',
                  borderBottom: 'solid rgba(0,0,0,0.2) 1px',
                  borderTop: 'solid rgba(0,0,0,0.2) 1px',
                  paddingTop: '6px',
                  paddingBottom: '6px',
                  marginBottom: '5px',
                  flexDirection: 'column'
                }}
              >
                <div style={{ fontSize: '100%' }}>
                  {order.orderNumber}
                </div>
                {order.shippingPersonCompany &&
                  <div style={{ fontSize: '90%', fontWeight: '500' }}>{order.shippingPersonCompany}</div>
                }
              </div>

              <div style={{ fontSize: '110%', fontWeight: '600', color: order.shipToday ? 'forestgreen' : '#927c08' }}>
                {order.shipToday ? 'Ships TODAY' : 'PREP Order'}
              </div>

              <div style={{marginBottom: '10px' }}>
                <span style={{ fontSize: '110%', fontWeight: '600' }}>
                  {(order.shippingCarrierName || "").toUpperCase()} {(order.shippingOption || "").toUpperCase()}
                </span>
              </div>
            </div>
          </div>
          <div style={{ width: '100%', fontSize: '100%'}}>
            <div>
              {order.items.map((item, i) => (
                <div
                  key={i}
                  style={{
                    display: 'flex',
                    flexWrap: 'nowrap'
                  }}
                >
                  <div style={{ width: '65%', padding: '5px', border: 'solid rgba(0,0,0,0.2) 1px', backgroundColor: 'white', fontSize: '90%'}}>{(item.name || "").toUpperCase()}</div>
                  <div style={{ width: '35%', padding: '5px', border: 'solid rgba(0,0,0,0.2) 1px', backgroundColor: 'white', fontSize: '90%' }}>{item.quantity}</div>
                </div>
                ))}

              
              {order.comments &&
                <div
                  style={{
                    fontSize: '90%'
                  }}
                >
                  <b>Comments:</b>
                  {order.comments}
                </div>
              }
            </div>
          </div>
          <div style={{ width: '100%', marginTop: '13px', borderTop: 'solid rgba(0,0,0,0.2) 1px', paddingTop: '10px' }}>
            <div
              style={{
                whiteSpace: "pre-line",
                fontSize: '12px%',
                // border: 'solid red 2px',
                maxHeight: '100px',
                borderBottom: (order.notes || "").length > 100 ? `solid ${priorityRef[order.priority].style} 2px` : '',
                overflowY: 'scroll'
              }}
            >
              {order.notes}
            </div>
          </div>
        </div>
        <div>
          <div style={{ width: '100%', marginTop: '5px', display: 'flex', justifyContent:'flex-end'}}>
            <div style={{ whiteSpace: "pre-line", fontSize: '90%', color: 'rgba(0,0,0,0.7)' }}>{format(order.created, "M/D/YY - HH:mm ")}</div>
          </div>
        </div>
      </div>
    )
  }
}
