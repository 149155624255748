import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Header from './components/common/header';
import './App.css';
import './components/common/common.css';
import UsersList from './components/usersList';
import AdminDashboard from './components/adminDashboard';

class App extends Component {
  render() {
		const {
      
      // authcontainer props
      user,
      socket,
      updateUser,
      
      // fetchlayer props
      orders,
      loading,
      posts,
      fetchOrders,
      changeAppState

    } = this.props;
    if (!user) return null
    return (
      <div>
        <Header
          changeAppState={changeAppState}
          user={user}
          // unreadMsg={unreadMsg}
          // unreadMsgString={unreadMsgString}
        />
        <Switch>
          <Route
            path="/users"
            render={({ history }) => (
              <UsersList user={user} />
            )}
          />
          <Route
            path="/"
            render={() => (
              <AdminDashboard
                ordersLoading={loading}
                user={user}
                orders={orders}
								socket={socket}
                updateUser={updateUser}
                fetchOrders={fetchOrders}
                posts={posts}
              />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default App;
