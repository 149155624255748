import React from 'react';
import { format } from 'date-fns'
import { activityRef } from '../refs/refs'

export default class OrderHistory extends React.Component {
    state = {
        activities: [],
    }
    componentDidMount() {
        const { order } = this.props;
        if (!order) return null;
        fetch(`/api/v1/activity?objId=${order._id}`)
            .then(res => res.json())
            .then(data => {
                this.setState({ activities: data })
            })
    }
    render() {
        const { activities } = this.state;

        return (
            <div
                className="app-item"
                style={{
                    marginTop: '5px'
                }}
             >
                <h4>Order Activity</h4>
                <div
                    style={{
                        fontSize: '90%'
                    }}
                >
                    {activities.map((activity, i) => (
                    <div
                        key={activity._id}
                        style={{
                            display: 'flex',
                            color: "#333",
                            flexDirection: 'column',
                            borderBottom: 'solid rgba(0,0,0,0.2) 1px',
                            borderTop: i === 0 ? 'solid rgba(0,0,0,0.2) 1px' : '',
                            marginTop: '5px',
                            marginBottom: '5px',
                            paddingTop: '8px',
                            paddingBottom: '8px'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                padding: '3px 0px'
                            }}
                        >
                            <i className="material-icons" style={{ fontSize: '100%', margin: '3px' }}>
                                {activityRef[activity.activityType] && activityRef[activity.activityType].icon}
                            </i>		
                            <div
                                style={{
                                    fontWeight: '600'
                                }}
                            >
                            {activityRef[activity.activityType] && activityRef[activity.activityType].title}
                            </div>
                        </div>
                        <div
                            style={{
                            fontsize: "85%"
                            }}
                        >
                            <div
                            style={{
                                color: "grey",
                                fontsize: '802'
                            }}
                            >
                            {activity.body}
                            </div>    
                        </div>
                        <div
                            style={{
                            // fontSize: '90%',
                            fontWeight: '500',
                            }}
                        >
                        {format(activity.created, "M/D/YY - HH:mm")}
                        </div>
                    </div>
                    ))}
                </div>
            </div>
        )
    }
}