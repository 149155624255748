import React from 'react';
import ListView from '../components/common/listview';
// import ChatForm from '../components/chatForm';
import OrderItem from '../components/orderItem'
import { Link } from 'react-router-dom'
import Loading from './common/loading'

export default class OrdersList extends React.Component {
  state = {
    currentFilter: 'current', // all, current, completed
    chatOpen: false,
    searchFilter: "",
    pendingOrders: [],
    pendingOrdersLoading: false
  }
  grabPending() {
    this.setState({
      pendingOrdersLoading: true
    })
    fetch('/api/v1/update-order').then(response => {
      this.setState({ pendingOrdersLoading: false})
      if (response.ok) {
        response.json().then(data => {
          console.log(data)
          this.setState({
            pendingOrders: data
          })
        })
      } else {
        console.log("not ok")
      }
    }).catch(console.log)
  }
	updateUser() {
		fetch('/api/v1/users', {
			method: "PUT",
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				_id: this.props.user._id,
				changes: {
					hasunreadMsg: false,
				},
			})
		})
			.then(response => response.json())
			.then(data => {
				this.props.updateUser(data);				
			})
  }

  pullAllOrders() {
    this.props.fetchOrders()
  }
  

  render() {
    const {
      orders,
      user,
      ordersLoading,
      updateAdminDashboardState,
    } = this.props;
    const { 
      currentFilter, 
      searchFilter, 
      pendingOrdersLoading, 
      pendingOrders = [] 
    } = this.state;


    const filteredOrders = (
      currentFilter === 'all' ?
        orders.sort((a,b) => a.created > b.created ? -1 : 1).sort((a, b) => a.created > b.created ? -1 : 1 ): 
        currentFilter === 'current' ? 
			orders.filter(order => order.status === "active").sort((a, b) => a.priority > b.priority ? 1 : -1 || a.created > b.created ? -1 : 1 ) :
          currentFilter === 'completed' ?
					orders.filter(order => order.status === 'finished').sort((a, b) => a.created > b.created ? -1 : 1 ) :
            currentFilter === "pending" ?
            pendingOrders :
            []
      )

    const searchFilteredOrders = !searchFilter ? filteredOrders : 
      filteredOrders.filter(o => o.shippingPersonCompany.toLowerCase().split(' ').join('').includes(searchFilter) || o.orderNumber.toLowerCase().split(' ').join('').includes(searchFilter))
    
    return (
      <div >
        <div className="grid-x">
          <div className="cell large-4" >
            <div
              style={{
                display: 'flex',
                height: '100%',
                alignItems: 'flex-end'
              }}
            >
              <div
                className="app-item"
                style={{
                  marginBottom: '0px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  cursor: 'pointer',
                  fontWeight: currentFilter === "pending" ? '700' : '400',
                  color: currentFilter === "pending" ? 'black' : 'grey',
                  boxShadow: currentFilter === "pending" ? '2px 2px 2px grey' : '',
                }}
                onClick={() => {
                  this.setState({ currentFilter: 'pending' })
                  this.grabPending();
                }}
              > 
                Pending Orders
              </div>
              <div
                className="app-item"
                style={{
                  marginBottom: '0px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  cursor: 'pointer',
                  fontWeight: currentFilter === "current" ? '700' : '400',
                  color: currentFilter === "current" ? 'black' : 'grey',
                  boxShadow: currentFilter === "current" ? '2px 2px 2px grey' : '',
                }}
                onClick={() => this.setState({ currentFilter: 'current' })}
              > 
                Current Orders
              </div>
              <div
                className="app-item"
                style={{
                  marginBottom: '0px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  cursor: 'pointer',
                  fontWeight: currentFilter === "completed" ? '700' : '400',
                  color: currentFilter === "completed" ? 'black' : 'grey',
                  boxShadow: currentFilter === "completed" ? '2px 2px 2px grey' : '',
                }}
                onClick={() => {
                  this.pullAllOrders()
                  this.setState({ currentFilter: 'completed' })
                }}
              > 
                Completed Orders
              </div>
            </div>
          </div>
          <div className="cell large-5 medium-6 small-6" >
            <div style={{ display: 'flex', alignItems: 'center', height: '100%'}}>
              <input
                placeholder="search"
                value={this.state.searchFilter}
                onChange={e => this.setState({ searchFilter: e.target.value })}
                style={{
                  marginBottom: '2px'
                }}
              />
            </div>
          </div>
          <div className="cell large-3 medium-6 small-6" >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end'
              }}
            >
            {((user && user.role === 'admin') &&  currentFilter === "pending") &&
              <div
                className="app-item"
                style={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  whiteSpace: 'nowrap',
                  marginRight: '10px',
                  marginBottom: '0px',
                  fontWeight: '700',
                  color: 'white',
                  backgroundColor: 'forestgreen',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  this.grabPending()
                }}
              >
                <i className="material-icons">add_box</i>
                Update
                </div>
              }
              {user && user.role === 'admin' &&
                <Link
                  className="app-item"
                  style={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    whiteSpace: 'nowrap',
                    marginRight: '10px',
                    marginBottom: '0px',
                    fontWeight: '700',
                    color: 'white',
                    backgroundColor: '#324b6f',
                    cursor: 'pointer'
                  }}
                  to="/orders/new"
                  onClick={() => updateAdminDashboardState({pendingOrderInfo: null})}
                >
                  <i className="material-icons">add_box</i>
                  Add Order
                  </Link>
                }
            </div>
          </div>
        </div>
			  {/* <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        > */}
          {/* <div
            style={{
              backgroundColor: "transparent",
              marginBottom: '0px',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              border: 'none',
              padding: '0px'
            }}
            // className="app-item"
          > */}
            {/* <div
              className="app-item"
              style={{
                marginBottom: '0px',
                paddingLeft: '20px',
                paddingRight: '20px',
                cursor: 'pointer',
                fontWeight: currentFilter === "current" ? '700' : '400',
                color: currentFilter === "current" ? 'black' : 'grey',
                boxShadow: currentFilter === "current" ? '2px 2px 2px grey' : '',
              }}
              onClick={() => this.setState({ currentFilter: 'current' })}
            > 
              Current Orders
            </div>
            <div
              className="app-item"
              style={{
                marginBottom: '0px',
                paddingLeft: '20px',
                paddingRight: '20px',
                cursor: 'pointer',
                fontWeight: currentFilter === "completed" ? '700' : '400',
                color: currentFilter === "completed" ? 'black' : 'grey',
                boxShadow: currentFilter === "completed" ? '2px 2px 2px grey' : '',
              }}
              onClick={() => {
                this.pullAllOrders()
                this.setState({ currentFilter: 'completed' })
              }}
            > 
              Completed Orders
            </div> */}
          {/* </div>
          {user && user.role === 'admin' &&
              
           
          <Link
            className="app-item"
            style={{
              display: 'flex',
              flexWrap: 'nowrap',
              whiteSpace: 'nowrap',
              marginRight: '10px',
              fontWeight: '700',
              color: 'white',
              backgroundColor: '#324b6f',
              cursor: 'pointer'
            }}
            to="/orders/new"
          >
            <i className="material-icons">add_box</i>
            Add Order
          </Link>
           }
        </div> */}
       
        <div
          className="app-item hide-for-small-only"
          style={{
            backgroundColor: "#333",
            width: '100%',
            color: 'white',
            fontWeight: '600',
            marginTop: '0px',
            padding: '5px 5px 5px 10px'
          }}
        >
          <div
            style={{
              display: 'flex',
            }}
          >
            <div
              style={{
                flex: '1'
              }}
            >
              
            </div>
            <div
              style={{
                flex: '2'
              }}
            >
              Date Added
            </div>
            <div
              style={{
                flex: "3",
              }}
            >
              Order #
            </div>
            <div
              style={{
                flex: "2",
              }}
            >
              Shipping
            </div>
            <div
              style={{
                flex: "3",
              }}
            >
              Items
            </div>
            <div
							style={{
							  flex: "2"
              }}
						>
              Status
						</div> 
            <div
              style={{
                flex: "3",
              }}
            >
              {/* {user.role === "admin" &&  */}
                Notes
              {/* } */}
            </div>
            {user.role === "viewer" &&
              <div style={{ flex: 2 }}>

              </div>
            }
          </div>
        </div>
        <ListView
          renderItem={({ item:order }) =>
            <div key={order._id} style={{ width: '100%'}}>
              <OrderItem
                order={order}
                user={user}
                currentFilter={currentFilter}
                updateAdminDashboardState={updateAdminDashboardState}
              />
            </div>
          }
          items={searchFilteredOrders}
          id="order-list"
        />
        
        {pendingOrdersLoading && Loading}
        {ordersLoading && Loading}
        {(orders.length === 0 && !ordersLoading) &&
          <div style={{ padding: '20px' }}>
            No Orders Found
          </div>
        }
				<div style={{ padding: '40px'}} />
        
      </div>
    )
  }
}
