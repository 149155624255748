import React from 'react';
import { Link } from 'react-router-dom';


export default class Header extends React.Component {
  render() {
    const {
      // changeAppState,
      // unreadMsg,
      // unreadMsgString,
      user,
    } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          padding: '20px',
          paddingBottom: '10px',
          backgroundColor: '#333',
          fontWeight: '600',
          color: 'white',
          boxShadow: "1px 1px 1px 2px lightgrey",
          border: "solid rgba(0,0,0,0.2) 1px"
        }}
      >
        <div>
          <Link
            to="/dashboard"
            style={{
              color: 'white',
              textDecoration: 'none',
              marginBottom: '8px'
              
            }}
          >
            <i className="material-icons">dashboard</i>
            View Dashboard
          </Link>
        </div>
        {user && user.role === 'admin' && 

          <div
            style={{
              display: 'flex',
              // justifyContent: 'space-between',
              flexWrap: 'wrap'
            }}
          >
            <Link
              style={{
                paddingRight: "30px",
                cursor: 'pointer',
              }}
              to="/"
            >
              <i className="material-icons">home</i>
              Home
            </Link>
            {user && user.role === 'admin' && 
              <Link
                style={{
                  paddingRight: "30px",
                  cursor: 'pointer',
                }}
                to="/users"
              >
              <i className="material-icons">supervised_user_circle</i>
                Users
              </Link>
            }

            <a
              href="https://chat.synaccess.io"
              style={{
                paddingRight: "30px",
                cursor: 'pointer',
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
            <i className="material-icons">chat</i>
              Chat
            </a>
            <a
                href="/"
                style={{
                  paddingRight: "30px",
                  cursor: 'pointer',
                }}
                onClick={() => {
                  localStorage.clear()
                  window.location.href = '/login'
                }}
              >
              <i className="material-icons">logout</i>
                Logout
              </a>
            {/* {user && user.role === 'admin' &&
              <a
                style={{
                  padding: "0px 30px",
                  cursor: 'pointer',
                }}
                onClick={() => changeAppState({ modalActive: "addOrder" })}
              >
                <i className="material-icons">add_box</i>
                Add Order
              </a>
            } */}
{/*             
            <a
              style={{
                padding: "0px 30px",
                cursor: 'pointer',
              }}
              onClick={() => changeAppState({ modalActive: "finishedOrders" })}
            >
              <i className="material-icons">done_all</i>
              Finished Orders
            </a> */}
          </div>
        }
        {user && user.role !== "admin" && 
          <div
            style={{
              display: 'flex',
              // justifyContent: 'space-between',
              flexWrap: 'wrap'
            }}
          >
            <a
              href="https://chat.synaccess.io/engineering/channels/az-production"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                paddingRight: "30px",
                cursor: 'pointer',
              }}
            >
            <i className="material-icons">chat</i>
              Chat
            </a>
            <a
              href="/"
              style={{
                paddingRight: "30px",
                cursor: 'pointer',
              }}
              onClick={() => {
                localStorage.clear()
                window.location.href = '/login'
              }}
            >
            <i className="material-icons">logout</i>
              Logout
            </a>

          </div>
        }
      </div>
    )
  }
}