import React from "react";
import Swal from "sweetalert2";
// import { distanceInWords } from 'date-fns'
import { Link } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
// import Dropzone from 'react-dropzone'

const shippingOptionList = [
  "Fedex GND",
  "Fedex Home",
  "Fedex Intl PO",
  "Fedex ECO",
  "Fedex Overnight",
  "Fedex 2DA",
  "DHL",
  "USPS Priority",
];

export default class AddOrderForm extends React.Component {
  state = {
    orderNumber: "",
    shippingOption: "",
    shippingCarrierName: "",
    shippingPersonCompany: "",
    shippingPersonCity: "",
    shippingPersonState: "",
    shippingPersonCountry: "",
    emailNotifications: "",
    creatorId: "",
    creatorName: "",
    items: [],
    status: "active",
    shippingPrinted: false,
    shipToday: false,
    notes: "",
    errors: {},
    priority: "5",
    products: [],
    _id: "",
    files: [],
    filesLoading: false
  };

  componentDidMount() {
    console.log(this.props)
    const { order, user } = this.props;
    this.setState({
      creatorId: user._id,
      creatorName: user.username,
    });
    if (order) {
      this.grabUploadedFiles(order._id);
      this.setState({
        _id: order._id,
        orderNumber: order.orderNumber,
        priority: order.priority,
        shippingOption: order.shippingOption,
        emailNotifications: order.emailNotifications,
        shippingCarrierName: order.shippingCarrierName,
        shippingPersonCompany: order.shippingPersonCompany,
        shippingPersonCity: order.shippingPersonCity,
        shippingPersonState: order.shippingPersonState,
        shippingPersonCountry: order.shippingPersonCountry,
        items: order.items,
        status: order.status,
        shippingPrinted: order.shippingPrinted,
        notes: order.notes,
        shipToday: order.shipToday,
      });
    }
    this.grabProducts();
  }

  grabProducts() {
    fetch("/api/v1/products")
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            this.setState({ products: data });
          });
        } else {
          console.log("not ok response");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  grabUploadedFiles = (orderId) => {
    this.setState({filesLoading: true})
    if (this.state._id || orderId) {
      fetch(`/api/v1/files?orderId=${this.state._id || orderId}`)
        .then((response) => {
          this.setState({filesLoading: false})
          if (response.ok) {
            response.json().then((data) => {
              console.log(data);
              this.setState({ files: data.files });
            });
          } else {
            console.log("no files found");
          }
        })
        .catch((e) => {
          console.log(e);
          // alert(JSON.stringify(e));
        });
    } else {
      console.log("skipping fetching uploaded files because no order ID");
    }
  };

  grabFile = (file) => {
    fetch(`/api/v1/files/${this.state._id}/${file.id}`)
    .then(function (response) {
      return response.blob();
    })
    .then(function (myBlob) {
      var objectURL = URL.createObjectURL(myBlob);
      document.querySelector("#pdf-frame").src = "";
      document.querySelector("#pdf-frame").src =
        objectURL;
      objectURL = URL.revokeObjectURL(myBlob);
    })
    .then(function () {
      window.setTimeout(function () {
        document
          .querySelector("#pdf-frame")
          .contentWindow.print();
      }, 420);
    });
  }

  removeFile = (fileId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to remove this file permanently?",
      icon: 'warning',
      showDenyButton: true,
      showCancelButton: true,
      cancelButtonColor: '#324b6f',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(({value:confirmed}) => {
      if (confirmed) {
        Swal.fire('Removed!', '', 'success');

        fetch(`/api/v1/files/${this.state._id}/${fileId}`, {
          method: "delete"
        }).then(response => {
            
          this.grabUploadedFiles(this.state._id);
        })
        console.log(fileId)
      } else  {
        Swal.fire("Changes are not saved", '', 'info')
      }
    })
  }

  handleUpload = (event) => {
    // event.target.files
    // event.preventDefault();
    const data = new FormData();
    // data.append("file", event.target.files[0]);

    data.append("file", event);
    data.append("orderId", this.state._id);
    // console.log(event.target.files);
    // data.append('name', 'Test Name');
    // data.append('desc', 'Test description');
    fetch("/api/v1/upload", {
      method: "POST",
      headers: {
        Accept: "application/json",
        //  'Content-Type': 'multipart/form-data'
      },
      body: data,
    }).then((response) => {
      response.text();
      this.grabUploadedFiles(this.state._id);
    });
  };
  submit() {
    const { order, history } = this.props;
    if (order && !order.pending ) {
      // if editing not creating new
      // let newState = this.state;
      // newState.items = newState.items.filter(item => item.name && item.quantity);
      // const updatedOrder = {...this.state, products};
      const { products,_id,  ...updatedOrder } = this.state;
      fetch("/api/v1/orders", {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          _id: order._id,
          changes: updatedOrder,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.err) {
            this.setState({ errors: data.err });
          } else {
            // add order to orders array
            // updateOrder(data)
            history.push("/");
            Swal.fire({
              position: "top-end",
              type: "success",
              title: "Order Successfully Modified",
              showConfirmButton: false,
              timer: 1000,
            });
          }
        });
    } else {
      const { products, _id, ...updatedOrder } = this.state;
      if (
        (updatedOrder.items && updatedOrder.items.length === 0) ||
        updatedOrder.orderNumber.toLowerCase() == "msg" ||
        updatedOrder.shippingPersonCompany.toLowerCase() == "msg"
      ) {
        Swal.fire({
          position: "center",
          title: "Error",
          text: "No Items Added. Please use Announcement section to post announcement.",
        });
      } else {
        fetch("/api/v1/orders", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            pkg: {
              ...updatedOrder,
              submittedTimestamp: Date.now(),
              status: "active",
            },
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.err) {
              this.setState({ errors: data.err });
            } else {
              history.push("/");
              // addNewOrder(data)
              Swal.fire({
                position: "top-end",
                type: "success",
                title: "Order Successfully Created",
                showConfirmButton: false,
                timer: 1000,
              });
            }
          });
      }
    }
  }

  removeOrder(cb) {
    const { order, removeOrder: removeOrderFromList } = this.props;

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        fetch("/api/v1/orders", {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            _id: order._id,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            // removeOrderFromList(data._id)
            this.props.history.push("/");
            cb && cb(null, true);
          });
      }
    });
  }
  render() {
    const inputProps = ({
      internal: field,
      external: label,
      type,
      placeholder,
    }) => ({
      onChange: (e) => this.setState({ [field]: e.target.value }),
      value: this.state[field],
      label: label,
      error: this.state.errors[field],
      type,
      placeholder,
    });

    const dropdownProps = ({ internal: field, external: label, options }) => ({
      onChange: (e) => this.setState({ [field]: e.target.value }),
      value: this.state[field],
      placeholder: label,
      label,
      options,
      error: this.state.errors[field],
    });
    const toggleProps = ({ internal: field, external: label, id, note }) => ({
      label,
      onChange: (e) => this.setState({ [field]: !this.state[field] }),
      value: this.state[field],
      error: this.state.errors[field],
      id,
      note,
    });

    const { items, products } = this.state;

    return (
      <div
        className="app-item"
        style={{
          marginTop: "5px",
          padding: "0px",
          // backgroundColor: "#333"
        }}
      >
        <div
          style={{
            display: "flex",
            // flexDirection: 'column',
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#333",
            margin: "0px",
            flexWrap: "wrap",
          }}
          className="app-item"
        >
          <h4
            style={{
              margin: "0px",
              // width: '100%',
              color: "white",
              margin: "0px",
              fontWeight: "600",
              // backgroundColor: "#333"
            }}
            // className="app-item"
          >
            {this.props.order ? "Edit Order" : "Create New Order"}
          </h4>
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "white",
                fontWeight: "600",
                // marginTop: '10px',
                whiteSpace: "pre",
                backgroundColor: "forestgreen",
                padding: "5px 20px",
                borderRadius: "2px",
                boxShadow: "rgba(0,0,0,0.2) 2px 2px 3px",
                marginRight: "10px",
                cursor: "pointer",
              }}
              onClick={() => this.submit()}
            >
              Save
            </div>
            <Link
              to="/"
              style={{
                display: "flex",
                alignItems: "center",
                color: "white",
                fontWeight: "600",
                // marginTop: '10px',
                whiteSpace: "pre",
                backgroundColor: "grey",
                padding: "5px 20px",
                borderRadius: "2px",
                boxShadow: "rgba(0,0,0,0.2) 2px 2px 3px",
              }}
            >
              Cancel
            </Link>
          </div>
        </div>

        <div
          style={{
            padding: "10px",
          }}
        >
          <div className="grid-x grid-padding-x grid-padding-y">
            <div className="cell large-6 medium-6 small-12">
              <h4
                style={{
                  borderBottom: "solid grey 1px",
                  paddingBottom: "5px",
                  marginBottom: "13px",
                }}
              >
                Basic Information
              </h4>
              <TextInput
                {...inputProps({
                  internal: "orderNumber",
                  external: "Order Number",
                  placeholder: "Type order number here",
                })}
              />
              <DropDownInput
                {...dropdownProps({
                  internal: "status",
                  external: "Status",
                  options: [
                    {
                      value: "active",
                      label: "Active",
                    },
                    {
                      value: "finished",
                      label: "Finished",
                    },
                  ],
                })}
              />
              <DropDownInput
                {...dropdownProps({
                  internal: "priority",
                  external: "Priority",
                  options: [
                    {
                      value: "1",
                      label: "1 (Highest Priority)",
                    },
                    {
                      value: "2",
                      label: "2 (High Priority)",
                    },
                    {
                      value: "3",
                      label: "3 (Normal Priority)",
                    },
                    {
                      value: "4",
                      label: "4 (Low Priority)",
                    },
                    {
                      value: "5",
                      label: "5 (Lowest Priority)",
                    },
                  ],
                })}
              />
            </div>
            <div className="cell large-6 medium-6 small-12">
              <h4
                style={{
                  borderBottom: "solid grey 1px",
                  paddingBottom: "5px",
                  marginBottom: "13px",
                }}
              >
                Shipping Informations
              </h4>
              <TextInput
                {...inputProps({
                  internal: "shippingPersonCompany",
                  external: "Company",
                  placeholder: "Type Company here",
                })}
              />
              <DropDownWithInput
                {...inputProps({
                  internal: "shippingCarrierName",
                  external: "Shipping",
                  placeholder: "Example: Fedex Ground",
                })}
                dropdownName="shipping options"
                options={shippingOptionList}
              />
              {/* {this.state.shippingCarrierName === "other" ?  */}
              {/* <TextInput {...inputProps({
                  internal: "shippingCarrierName",
                  external: "Shipping",
                  placeholder: "Example: Fedex Ground"
                })} /> */}
              {/* // : 
                // <DropDownInput
                //   {...dropdownProps({
                //     internal: "shippingCarrierName",
                //     external: "Shipping",
                //     options: [{
                //       value: "Fedex GND",
                //       label: "Fedex GND"
                //     },{
                //       value: "Fedex Home",
                //       label: "Fedex Home",
                //     },{
                //       value: "Fedec Intl PO",
                //       label: "Fedec Intl PO"
                //     },{
                //       value: "Fedex ECO",
                //       label: "Fedex ECO"
                //     },{
                //       value: "Fedex Overnight",
                //       label: "Fedex Overnight",
                //     },{
                //       value: "Fedex 2DA",
                //       label: "Fedex 2DA",
                //     },{
                //       value: "DHL",
                //       label: "DHL",
                //     },{
                //       value: "USPS Priority",
                //       label: "USPS Priority",
                //     }, {
                //       value: "other",
                //       label: "other"
                //     }]
                //   })}
                // />
              // }
              */}
              <div style={{ display: "flex" }}>
                <ToggleInput
                  {...toggleProps({
                    internal: "shippingPrinted",
                    external: "Labels Printed",
                    id: "shipping",
                    style: { flex: 1 },
                  })}
                  style={{ flex: 1 }}
                />
                <ToggleInput
                  {...toggleProps({
                    internal: "shipToday",
                    external: "Ships Today",
                    // note: 'If you do not select this option, it will be set to prep',
                    id: "today",
                  })}
                  style={{ flex: 1 }}
                />
                <div>
                  <ToggleInput
                    {...toggleProps({
                      internal: "emailNotifications",
                      external: "Email Notification",
                      // note: 'If you do not select this option, it will be set to prep',
                      id: "email",
                    })}
                    style={{ flex: 1 }}
                  />

                </div>
              </div>
            </div>
          </div>
          <div style={{ padding: "10px" }} />
          {this.state._id ? (
            <React.Fragment>
              <div
                style={{
                  marginLeft: "15px",
                  marginRight: "15px",
                }}

              >
                <FileUploader
                  multiple={false}
                  handleChange={this.handleUpload}
                  name="file"
                  types={["pdf"]}
                  classes="inputclass"
                />
                {/* <form>
                  <label>Paperwork PDFs</label>
                  <label for="file-upload" class="custom-file-upload">
                  <input
                    type="file"
                    id="file-upload"
                    name="fileUpload"
                    onChange={this.handleUpload}
                    style={{
                      height: "200px",
                      width: "100%",
                    }}
                  />
                  </label>
                </form> */}
              </div>
              <iframe id="pdf-frame" style={{ display: "none" }}></iframe>
              <div
                style={{
                  display: "flex",
                  flexDirection: 'column',
                  marginLeft: "15px",
                  marginRight: "15px",
                  marginTop: "10px",
                }}

              >
                {this.state.filesLoading && "Loading"}
                {this.state.files.map((file) => {
                  return (
                    <div
                      key={file.id}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: 'space-between',
                        padding: "5px",
                      }}
                    >
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          this.grabFile(file)
                        }}
                      >
                        <i className="material-icons">print</i>
                        <span>{file.name}</span>
                      </span>
                      <div
                        style={{
                          cursor: 'pointer'
                        }}
                        onClick={()=> {
                          this.removeFile(file.id);
                        }}
                      >
                        <i className="material-icons">cancel</i>
                      </div>
                    </div>
                  );
                })}
              </div>
            </React.Fragment>
          ) : (
            "Save before uploading pdfs"
          )}

          <div style={{ padding: "10px" }} />
          <div
            style={{
              marginLeft: "15px",
              marginRight: "15px",
            }}
          >
            <h4
              style={{
                borderBottom: "solid grey 1px",
                paddingBottom: "5px",
                marginBottom: "13px",
              }}
            >
              Notes and Specifications
            </h4>
            <TextInput
              {...inputProps({
                internal: "notes",
                external: "",
                type: "textarea",
                placeholder: "Type notes and specs here",
              })}
            />
          </div>
          <div
            style={{
              marginLeft: "15px",
              marginRight: "15px",
              marginTop: "20px",
            }}
          >
            <h4>Items Manifest</h4>
            {items.length > 0 && (
              <div
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  backgroundColor: "white",
                }}
              >
                <div
                  style={{
                    width: "65%",
                    fontWeight: "600",
                    padding: "10px 5px",
                    borderRadius: "3px 0px 0px 3px",
                    border: "solid rgba(0,0,0,0.2) 1px",
                    color: "white",
                    backgroundColor: "#333",
                  }}
                >
                  Name
                </div>
                <div
                  style={{
                    width: "35%",
                    fontWeight: "600",
                    padding: "10px 5px",
                    border: "solid rgba(0,0,0,0.2) 1px",
                    color: "white",
                    backgroundColor: "#333",
                  }}
                >
                  Count
                </div>
                <div
                  style={{
                    width: "5%",
                    fontWeight: "600",
                    padding: "10px 5px",
                    borderRadius: "0px 3px 3px 0px",
                    border: "solid rgba(0,0,0,0.2) 1px",
                    color: "white",
                    backgroundColor: "#333",
                  }}
                ></div>
              </div>
            )}
            {items.map((item, i) => (
              <div
                key={i}
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                }}
              >
                <div
                  style={{
                    width: "65%",
                    padding: "5px",
                    border: "solid rgba(0,0,0,0.2) 2px",
                    borderTop: "none",
                  }}
                >
                  <DropDownWithInput
                    inputStyle={{
                      margin: "0px",
                      boxShadow: "none",
                      border: "none",
                    }}
                    value={item.name}
                    placeholder="Item Name"
                    dropdownName="ecwid-products"
                    options={products.map((prod) => prod.name)}
                    onChange={(e) =>
                      this.setState({
                        items: items.map((item, y) => {
                          if (i === y) {
                            let newItem = item;
                            newItem.name = e.target.value;
                            return newItem;
                          } else {
                            return item;
                          }
                        }),
                      })
                    }
                  />
                </div>
                <div
                  style={{
                    width: "35%",
                    padding: "5px",
                    borderBottom: "solid rgba(0,0,0,0.2) 2px",
                  }}
                >
                  <input
                    type="number"
                    style={{
                      margin: "0px",
                      boxShadow: "none",
                      border: "none",
                    }}
                    value={item.quantity}
                    placeholder="Quantity"
                    onChange={(e) =>
                      this.setState({
                        items: items.map((item, y) => {
                          if (i === y) {
                            let newItem = item;
                            newItem.quantity = e.target.value;
                            return newItem;
                          } else {
                            return item;
                          }
                        }),
                      })
                    }
                  />
                </div>
                <div
                  style={{
                    width: "5%",
                    padding: "5px",
                    border: "solid rgba(0,0,0,0.2) 2px",
                    borderTop: "none",
                  }}
                >
                  <div
                    className="cancel-item"
                    onClick={() =>
                      this.setState({
                        items: items.filter((it, y) => i !== y),
                      })
                    }
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "100%",
                      alignItems: "center",
                      fontSize: "140%",
                    }}
                  >
                    &times;
                  </div>
                </div>
              </div>
            ))}
            <div
              style={{
                display: "flex",
                paddingTop: "10px",
                fontSize: "110%",
              }}
            >
              <div
                style={{
                  padding: "10px",
                  // border: 'solid rgba(0,0,0,0.2) 1px',
                  // borderRadius: '2px',
                  cursor: "pointer",
                  boxShadow: "1px 1px 1px 1px solid grey",
                  display: "flex",
                  alignItems: "center",
                }}
                className="button"
                onClick={() => {
                  this.setState({
                    items: [...items, { name: "", quantity: 0 }],
                  });
                }}
              >
                <i className="material-icons">add</i>Add New Item to Manifest
              </div>
            </div>
          </div>

          <div style={{ padding: "10px" }} />

          {/* <div className="grid-x grid-padding-x grid-padding-y">
              <div className="cell large-6 medium-6 small-12">
                <h4>Shipping Paperwork</h4>
                <ToggleInput {...toggleProps({
                  internal: "shippingPrinted",
                  external: "Labels Printed",
                  id: 'shipping',
                })} />
              </div>
              <div className="cell large-6 medium-6 small-12">
                <h4>Notes and Specifications</h4>
                <TextInput {...inputProps({
                  internal: "notes",
                  external: "Notes",
                  type: 'textarea'
                })} />
              </div>
          </div> */}
        </div>

        <div
          style={{
            padding: "10px",
          }}
          className="grid-x grid-padding-x grid-padding-y"
        >
          <div className="cell">
            {/* <div
              style={{
                padding: '10px 5px',
                // margin: '5px',
                backgroundColor: "forestgreen",
                color: "white",
                fontSize: '110%',
                fontWeight: '600',
                borderRadius: '2px',
                boxShadow: '0px 0px 0px 1px solid grey 2px',
                margin: "20px 0px",
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center'
              }}
              onClick={() => this.submit()}
            >
              Save
            </div> */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {this.props.order && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "white",
                    fontWeight: "600",
                    // marginTop: '10px',
                    whiteSpace: "pre",
                    backgroundColor: "maroon",
                    padding: "5px 20px",
                    borderRadius: "2px",
                    boxShadow: "rgba(0,0,0,0.2) 2px 2px 3px",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => this.removeOrder()}
                >
                  Remove Order Permanently
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "white",
                  fontWeight: "600",
                  // marginTop: '10px',
                  whiteSpace: "pre",
                  backgroundColor: "forestgreen",
                  padding: "5px 20px",
                  borderRadius: "2px",
                  boxShadow: "rgba(0,0,0,0.2) 2px 2px 3px",
                  marginRight: "10px",
                  cursor: "pointer",
                }}
                onClick={() => this.submit()}
              >
                Save
              </div>
            </div>

            {/* {this.props.order &&
              <div
                className="button"
                style={{
                  fontWeight: '600',
                  fontSize: '90%',
                  backgroundColor: 'maroon'
                }}
                onClick={() => this.removeOrder()}
              >
                Remove Order Permanently
              </div>
            } */}
          </div>
        </div>
      </div>
    );
  }
}

const TextInput = ({
  label,
  onChange,
  placeholder,
  value,
  error,
  type,
  note,
}) => {
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <label>{label}</label>
        {error && <div style={{ color: "red" }}>{error}</div>}
        {note && (
          <div>
            <em>{note}</em>
          </div>
        )}
      </div>
      {type === "textarea" ? (
        <textarea
          rows="2"
          value={value}
          placeholder={placeholder}
          type={type}
          onChange={onChange}
        />
      ) : (
        <input
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          type={type}
        />
      )}
    </div>
  );
};

const DropDownInput = ({
  label,
  onChange,
  placeholder,
  value,
  options,
  error,
}) => (
  <div>
    {label && (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <label>{label}</label>
        {error && <div style={{ color: "red" }}>{error}</div>}
      </div>
    )}
    <select value={value} onChange={onChange} placeholder={placeholder}>
      <option value=""> </option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label || option.value}
        </option>
      ))}
    </select>
  </div>
);

const ToggleInput = ({ label, onChange, value, error, id, note, style }) => (
  <div
    style={{
      padding: "10px 0px",
      marginBottom: "10px",
      ...style,
    }}
  >
    <div style={{ padding: "5px 0px" }}>{label}</div>
    <input
      className="tgl tgl-light"
      id={`cb${id}`}
      type="checkbox"
      checked={value}
      onChange={onChange}
    />
    <label className="tgl-btn" htmlFor={`cb${id}`} />
    {note && (
      <div style={{ fontSize: "90%", marginBottom: "10px", marginTop: "10px" }}>
        <em>{note}</em>
      </div>
    )}
  </div>
);

const DropDownWithInput = ({
  label,
  onChange,
  placeholder,
  value,
  error,
  type,
  note,
  options,
  dropdownName,
  inputStyle = {},
}) => {
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <label>{label}</label>
        {error && <div style={{ color: "red" }}>{error}</div>}
        {note && (
          <div>
            <em>{note}</em>
          </div>
        )}
      </div>
      <input
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        list={dropdownName}
        style={inputStyle}
      />
      <datalist id={dropdownName}>
        {options.map((opt) => {
          return <option key={opt} value={opt} />;
        })}
      </datalist>
    </div>
  );
};
