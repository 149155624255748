import React from 'react';
import { Route, Switch } from "react-router-dom";
import Dashboard from './dashboard';
import Login from './components/login'
import App from './App';
import AuthContainer from './components/authContainer'
import io from 'socket.io-client';

const  socket  =  io();
const AuthorizedApp = AuthContainer(App, socket);

export default class FetchLayer extends React.Component {
  state = {
    orders: [],
    loading: false,
    allOrdersPulled: false,
    posts: [],
    pinnedPosts: [],
    time: new Date(),
  }

  componentDidMount() {
    this.fetchPosts();
    this.fetchOrders(false, "active"); // pull only active orders

    socket.on("order_update", this.updateOrder);
    socket.on("order_new", this.addNewOrder);
    socket.on("remove_order", this.removeOrder);

    socket.on("post_create", this.createPost)    
    socket.on("post_destroy", this.destroyPost)
    socket.on("post_update", this.updatePost);
    this.interval = setInterval(() => {
      this.fetchOrders(false, "active"); // should be true, false for dev
      this.setState({ time: new Date() })
    }, 60000);
  }

  componentWillUnmount() {
    socket.removeListener("order_update");
    socket.removeListener("order_new");
    socket.removeListener("remove_order");
  }

  changeAppState = newState => this.setState(newState)

  createPost = (newPost) => {
    console.log("creating post")
    const { posts } = this.state;
    const updatedPosts = [newPost, ...posts]
    this.setState({ posts: updatedPosts })
    new Audio('/ting.mp3').play();
  }

  destroyPost = (removedPost) => {
    const { posts } = this.state;
    const filteredPosts = posts.filter(post => post._id !== removedPost._id);
    this.setState({ posts: filteredPosts })
  }

  updatePost = updatedPost => {
    const { posts } = this.state;
    const updatedPosts = posts.map(post =>
      post._id === updatedPost._id ? updatedPost : post
    )
    this.setState({ posts: updatedPosts })
  }

  fetchPosts = () => {
    fetch('/api/v1/posts').then(response => {
      if (response.ok) {
        response.json().then(data => {
          this.setState({ posts: data})
        })
      }
    }).catch(error => {
      console.log(error)
    })
  }

  updateOrder = (updatedOrder) => {
    const { orders } = this.state;
    const updatedOrders = orders.map(order =>
      order._id === updatedOrder._id ? updatedOrder : order
    )
    this.setState({ orders: updatedOrders })
  }

  addNewOrder = (newOrder) => {
    const { orders } = this.state;
    const updatedOrders = [newOrder, ...orders]
    this.setState({ orders: updatedOrders })
    new Audio('/ding.mp3').play();
  }
  
  removeOrder = (removedOrder) => {
    const { orders } = this.state;
    const filteredOrders = orders.filter(order => order._id !== removedOrder._id);
    this.setState({ orders: filteredOrders })
  }

  fetchOrders = (cb, status) => {
    if (this.state.allOrdersPulled) return null;
    this.setState({ loading: true });
    fetch(`/api/v1/orders${status ? `?status=${status}` : "" }`)
      .then(response => response.json())
      .then(data => {
        if (!status) this.setState({ allOrdersPulled: true })
        this.setState({ loading: false });
        if (data) {
          this.setState({ orders: data })
					if (cb) {
						cb(null, true)
					}
				} else {
					if (cb) {
						cb(true)
					}
        }
      })
  }

  render() {
    const {
      orders,
      loading,
      posts,
      time
    } = this.state;

    return (
      <Switch>
        <Route
          path="/dashboard"
          render={(props) => (
            <Dashboard
              {...props} 
              socket={socket}
              orders={orders}
              posts={posts}
              time={time}
            />
          )}
        />,
        <Route
          path="/login"
          component={Login}
        />,
        <Route
          path="/"
          // component={AuthorizedApp}
          render={() => (
            <AuthorizedApp
              orders={orders}
              loading={loading}
              posts={posts}
              fetchOrders={this.fetchOrders}
              changeAppState={this.changeAppState}
            />
          )}
        />
      </Switch>
    )
  }
}
