import React from 'react';
import Swal from 'sweetalert2'
import { format } from 'date-fns';

export default class PostsList extends React.Component {
  confirmPost = (post) => {
    fetch('/api/v1/posts', {
      method: "PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        _id: post._id,
        changes: {
          confirmed: true,
        }
      })
    })

  }
  removePost = (post) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You will remove this Admin Post forever",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        fetch('/api/v1/posts', {
          method: "DELETE",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            _id: post._id
          })
        })
      }
    })
  }
  render() {
    const { posts, user } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap'
        }}
      >
        {posts.map(post => {
          return (
            <div
              key={post._id}
              className="app-item"
              style={{
                padding: '0px'
              }}
            >
              <div
                style={{
                  fontWeight: '800',
                  padding: '10px',
                  backgroundColor: "#333",
                  color: 'white' ,
                  borderRadius: '3px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                  // fontSize: '125%'
                }}
              >
                <span>Announcement</span>
                {user && user.role === "admin" &&
                  <i
                    className="material-icons"
                    style={{ margin: '0px', cursor: 'pointer' }}
                    onClick={() => this.removePost(post)}
                  >
                    close
                  </i>
                }
              </div>
              <div
                style={{
                  padding: '10px'
                }}
              >
                {post.message || post.body}
              </div>  
              {user && user.role === 'admin' && post.confirmationRequired && !post.confirmed && 
                <div
                  style={{
                    color: 'grey',
                    padding: '10px'
                  }}
                >
                  Requires Confirmation
                </div>
              }
              
              {user && user.role === 'admin' && post.confirmationRequired && post.confirmed && 
                <div style={{ padding: '10px', display: 'flex', justifyContent: 'center' }}>
                  <b>Confirmed</b>
                </div>
              }
              
              {user && (user.role === "viewer" || user.role === "lead") && post.confirmationRequired && !post.confirmed && 
                <div
                  className="button"
                  style={{
                    margin: '0px 10px',
                    backgroundColor: 'forestgreen'
                  }}
                  onClick={() => this.confirmPost(post)}
                >
                  Confirm
                </div>
              }
              {user && (user.role === "viewer" || user.role === "lead") && post.confirmationRequired && post.confirmed && 
                <div style={{ padding: '10px', display: 'flex', justifyContent: 'center' }}>
                  <b>Confirmed</b>
                </div>
              }
              <div
                style={{
                  fontSize: '85%',
                  color: 'grey',
                  display: 'flex',
                  padding: '10px',
                  justifyContent: 'flex-end'
                }}
              >
                {format(post.create_at || post.created, "M/D/YY - HH:mm")}
              </div>
            </div>
          )
        })}

      </div>
    )
  }
}