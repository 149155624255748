import React from 'react';
import { Redirect } from 'react-router-dom';


function AuthContainer(WrappedComponent, socket) {
  return class extends React.Component {
    state = {
      authorized: null,
      user: {},
		}

		updateUser = user => this.setState({ user });

		componentDidMount() {
			socket.on("update_user", updatedUser => {
				this.fetchUser();
			})
			// socket.on("new_message", msg => {
				// console.log("New Message Foundo n AUth Container");
				// if (msg.authorId !== this.state.user._id) {
					// var user = this.state.user;
					// user.hasunreadMsgs = true;
					// this.setState({ user })
				// }
			// })
			if (localStorage.token) {
				this.fetchUser();
      }  else {
        this.setState({ authorized: false })
        console.log("NO token found")  
      }
		}
		fetchUser() {
			fetch(`/api/v1/token?t=${localStorage.token}`)
				.then(response => response.json())
				.then(data => {
					if (data && data._id) {
						this.setState({ authorized: true, user: data });
					} else {
						console.warn("Bad token")
						this.setState({ authorized: false })
					}
				})
		}
		render() {
			const { authorized, user  } = this.state;
      if (authorized === null) {
        return null
      } else if (authorized === false) {
        return <Redirect to="/login" />
      } else {
				return <WrappedComponent
				  socket={socket}
				  user={user}
          updateUser={this.updateUser}
          {...this.props}
				/>
      }
      
    }
  }  
}

export default AuthContainer
