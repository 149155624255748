import React from 'react';

export default class ListView extends React.Component {
  state = {
      numPageScrolls: 1,
  }

  componentDidMount() {
    document.addEventListener('scroll', this.trackScrolling);
  }
  
  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }
  
  trackScrolling = () => {
    const wrappedElement = document.getElementById(this.props.id);
    if (wrappedElement.getBoundingClientRect().bottom <= window.innerHeight) {
      console.log('header bottom reached');
      this.setState({ numPageScrolls: this.state.numPageScrolls + 1 })
    }
  };
  render() {
      const {
        renderItem,
        items,
        id,
      } = this.props;
      const { numPageScrolls } = this.state;

      const slicedItems = items.slice(0,50 * numPageScrolls);

      return (
          <div id={id} style={{ width: '100%' }}>
              {slicedItems.map(item => renderItem({item}))}
          </div>
      )
  }
}