import React from 'react'
import { Link } from 'react-router-dom'
import { format, distanceInWords } from 'date-fns'
import { priorityRef } from '../refs/refs';
import Swal from 'sweetalert2'

export default class OrderItem extends React.Component {

  confirm() {
    const { order } = this.props;

    fetch('/api/v1/orders', {
      'method': 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        _id: order._id,
        changes: {
          confirmed: true,
        }
      }),
    }).then(response => response.json())
      .then(data => {
        // fetchOrders(); // reget all again
      })
  }

  modifyStatus(status, cb) {
    const { order } = this.props;
    const confirmed = status === "active" ? false : order.confirmed; 
    fetch('/api/v1/orders', {
      'method': 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        _id: order._id,
        changes: {
          status,
          confirmed,
        }
      }),
    }).then(response => response.json())
      .then(data => {
        // fetchOrders(); // reget all again
        cb && cb(null, true)
      })
  }

  modifyStatusConfirm(status, cb) {
    const { order } = this.props;
    const confirmed = status === "active" ? false : order.confirmed; 

    Swal.fire({
      title: order.status === "active" ? 'Confirm Finishing Shipment' : 'Confirm Setting Shipment Active',
      // text: "You won't be able to revert this!",
      // type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#324b6f',
      cancelButtonColor: 'maroon',
      confirmButtonText: 'Done!'
    }).then((result) => {
      if (result.value) {
        fetch('/api/v1/orders', {
          'method': 'PUT',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            _id: order._id,
            changes: {
              status,
              confirmed
            }
          }),
        }).then(response => response.json())
          .then(data => {
            cb && cb(null, true)
          })
      }
    })
    
  }

  render() {
    const { order, user, updateAdminDashboardState } = this.props;
    const isAdminUser = user.role === "admin";
    const ConditionalLink = isAdminUser ? Link : "div";
    return [(
      <div
        className="app-item hide-for-small-only"
        key="large-screen-order-item"
        style={{
        color: "#222",
        marginBottom: '0px',
        marginTop: '0px',
				padding: '5px',
				paddingLeft: '10px',
				// border: order.confirmed ? undefined : 'solid rgba(128,0,0,0.6) 2px',
        borderRadius: '2px',
        opacity: order.status === 'finished' ? '0.8' : '1.0'
      }}
    >

      <ConditionalLink
        // to={`/orders/${order.status === "pending" ? "new" : order._id}`}
        to={{
          pathname: `/orders/${order.status === "pending" ? "new" : order._id}`,
        }}
        onClick={() => updateAdminDashboardState({ pendingOrderInfo: order })}
        style={{
          display: 'flex',
          cursor: isAdminUser ? 'pointer': 'default',
          color: "#333"
        }}
      >
        <div
          style={{
            flex: '1',
            // fontStyle: 'italic',
            // color: '#555',
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            justifyContent: 'center',
            fontStyle: 'normal',

            fontSize: '85%',
            fontWeight: '600',
            color: priorityRef[order.priority].style,
            // color: 'white',
            padding: '3px',
            paddingLeft: '0px'
          }}
        >
         <span style={{ fontWeight: '800', marginRight: '3px' }}>({order.priority})</span>
          {priorityRef[order.priority].title}
        </div>
        <div
          style={{
            flex: '2',
            fontSize: '75%',
            color: '#555',
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            justifyContent: 'center',
            fontStyle: 'normal'
          }}
        >
          {format(order.created, "M/D/YY \n HH:mm ")}
          <div
            style={{
              fontWeight: '600',
              fontSize: '90%'
            }}
          >
            {distanceInWords(order.created, new Date())} ago
          </div>
        </div>
        <div
          style={{
            flex: "3",
            fontWeight: '600',
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          
         <div
          style={{
            fontSize: '82%',
            color: 'rgba(0,0,0,0.6)',
          }}
         >
           {order.shippingPersonCompany}
         </div>
         <div style={{ fontSize: '75%' }} >{order.orderNumber}</div>

        </div>
        <div
          style={{
            flex: "2",
            fontSize: '90%',
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
        >
          <div
            style={{
              fontWeight: '600',
              color: order.shipToday ? order.status === "finished" ? "grey" : 'forestgreen' : 'rgb(146, 124, 8)'
            }}
          >
            {order.shipToday ? order.status === "finished" ? "Shipped" : "Ships Today" : "Prepping"}
          </div>
          <div>{order.shippingCarrierName} {order.shippingOption}</div>
        </div>
        <div
          style={{
            flex: "3",
          }}
        >
          {order.items.map((item, i) => (
            <div
              key={i}
              style={{
                display: 'flex',
                flexWrap: 'nowrap',
                fontSize: '85%'
                // whiteSpace: "pre",
              }}
            >
              <div style={{  padding: '5px', paddingTop: '2px', paddingBottom : '2px' }}>{(item.name || "").toUpperCase()} × <b>{item.quantity}</b></div>
            </div>
          ))}
				</div>
				{order.status === "finished" ? 
					
					<div
						style={{
							flex: "2",
							fontSize: "90%",
							display: "flex",
							alignItems: "flex-start",
							flexDirection: 'column',
							justifyContent: 'center'
						}}
					>
						<div>Finished:</div>
						<div><b>{format(order.timeFinished, "M/D/YY \n HH:mm ")}</b></div>
					</div> : 
					<div
						style={{
							flex: "2",
							fontSize: "90%",
							display: "flex",
							alignItems: "flex-start",
							flexDirection: 'column',
							justifyContent: 'center'
						}}
					>
						<div
							style={{
								color: order.confirmed ? "forestgreen" : "maroon",
								display: 'flex',
								alignItems: 'center'
							}}
						>
							<i
								className="material-icons"
								style={{ fontSize: '110%'}}
							>
								{order.confirmed ? "check_circle" : "check_circle_outline"}
							</i>
							{order.confirmed ? "Confirmed" : "Pending"}
						</div>
						<div
							style={{
								color: order.shippingPrinted ? "forestgreen" : "maroon",
								display: 'flex',
								alignItems: 'center'
							}}
						>
							<i
								className="material-icons"
								style={{ fontSize: '110%'}}
							>
								{order.shippingPrinted ? "print" : "print_disabled"}
							</i>

							{order.shippingPrinted ? "Printed" : "Needs"}
						</div>
					</div>
				}
        {/* {(user.role === "admin") &&  */}
          <div
            style={{
              flex: "3",
              fontSize: '80%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {order.notes}
          </div>
        {/* } */}
        {user.role === "viewer" &&
          <div
            style={{
              flex: "2",
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          > 
            <div
              className="button"
              style={{
                backgroundColor: order.status === "active" ? order.confirmed ? "forestgreen" : "#324b6f" : "#324b6f",
                padding: '10px 5px',
                marginBottom: '0px'
              }}
              onClick={() => (!order.confirmed && order.status === "active") ? this.confirm() : this.modifyStatusConfirm(order.status === "active" ? "finished" : "active")}
            >
              {order.status === "active" ? 
                order.confirmed ? 
                "Set Finished" :
                "Confirm order" :
                "Set Active"
              }
            </div>
          </div>
        }

      </ConditionalLink>
    </div>
    /*  FOR SMALL SCREENS ONLY  */
    ), (

      <div
        className="app-item show-for-small-only"
        key="small-screen-order-item"
        style={{
        color: "#222",
        marginBottom: '0px',
        marginTop: '0px',
				padding: '5px',
				paddingLeft: '10px',
				// border: order.confirmed ? undefined : 'solid rgba(128,0,0,0.6) 2px',
        borderRadius: '2px',
        opacity: order.status === 'finished' ? '0.8' : '1.0'
      }}
    >

      <ConditionalLink
        to={`/orders/${order._id}`}
        style={{
          display: 'flex',
          // flexDirection: 'column',
          alignItems: 'flex-start',
          cursor: isAdminUser ? 'pointer': 'default',
          color: "#333"
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
            // border: 'solid red 2px',
            // flex: 1
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            {/* <span style={{ fontWeight: '800', marginRight: '3px' }}>()</span> */}
            
            <div
              style={{
                fontSize: '82%',
                // color: 'rgba(0,0,0,0.6)',
              }}
            >
              <div> {order.shippingPersonCompany} </div>

              <div
                style={{
                  fontSize: '125%',
                  fontWeight: '600',
                  color: "#333",
                }}
              >
                {order.orderNumber}
              </div>
            </div>
            <div
              style={{
                color: priorityRef[order.priority].style,
                fontSize: '85%',
                fontWeight: '600'
              }}
            >
              ({order.priority}) {priorityRef[order.priority].title}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              // justifyContent: 'space-between'
            }}
          >
            <div
              style={{
                fontWeight: '600',
                paddingRight: '10px',
                color: order.shipToday ? order.status === "finished" ? "grey" : 'forestgreen' : 'rgb(146, 124, 8)'
              }}
            >
              {order.shipToday ? order.status === "finished" ? "Shipped" : "Ships Today" : "Prepping"}
            </div>

            <div>{order.shippingCarrierName} {order.shippingOption}</div>

          </div>
          <div
            style={{
              display: 'flex'
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: 'column',
                flex: 1,
                // border: 'solid red'
              }}
            >


              {order.status === "finished" ? 
                <div
                  style={{
                    // flex: "2",
                    display: 'flex',
                    fontSize: "90%",
                    // display: "flex",
                    // alignItems: "flex-start",
                    // flexDirection: 'column',
                    // justifyContent: 'center'
                  }}
                >
                  <div>Finished:</div>
                  <div><b>{format(order.timeFinished, "M/D/YY \n HH:mm ")}</b></div>
                </div> : 
                <div
                  style={{
                    // flex: "2",
                    fontSize: "90%",
                    // display: "flex",
                    // alignItems: "flex-start",
                    // flexDirection: 'column',
                    // justifyContent: 'center'
                  }}
                >
                  <div
                    style={{
                      color: order.confirmed ? "forestgreen" : "maroon",
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <i
                      className="material-icons"
                      style={{ fontSize: '110%'}}
                    >
                      {order.confirmed ? "check_circle" : "check_circle_outline"}
                    </i>
                    {order.confirmed ? "Confirmed" : "Pending"}
                  </div>
                  <div
                    style={{
                      color: order.shippingPrinted ? "forestgreen" : "maroon",
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <i
                      className="material-icons"
                      style={{ fontSize: '110%'}}
                    >
                      {order.shippingPrinted ? "print" : "print_disabled"}
                    </i>

                    {order.shippingPrinted ? "Printed" : "Needs"}
                  </div>
                </div>
              }
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: 'column',
                flex: 1,
                // border: 'solid blue'
              }}
            >
              <div
                style={{
                  // flex: "3",
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                {order.items.map((item, i) => (
                  <div
                    key={i}
                    style={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                      fontSize: '85%'
                      // whiteSpace: "pre",
                    }}
                  >
                    <div style={{  padding: '5px', paddingTop: '2px', paddingBottom : '2px' }}>{(item.name || "").toUpperCase()} × <b>{item.quantity}</b></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          

          <div
            style={{
              // flex: "3",
              fontSize: '80%',
              // display: 'flex',
              // alignItems: 'center',
            }}
          >
            {order.notes}
          </div>

          
          
          {user.role === "viewer" &&
            <div
              style={{
                // flex: "2",
                // display: 'flex',
                // alignItems: 'center',
                // justifyContent: 'center'
              }}
            > 
              <div
                className="button"
                style={{
                  backgroundColor: order.status === "active" ? order.confirmed ? "forestgreen" : "#324b6f" : "#324b6f",
                  padding: '10px 5px',
                  marginBottom: '0px'
                }}
                onClick={() => (!order.confirmed && order.status === "active") ? this.confirm() : this.modifyStatusConfirm(order.status === "active" ? "finished" : "active")}
              >
                {order.status === "active" ? 
                  order.confirmed ? 
                  "Set Finished" :
                  "Confirm order" :
                  "Set Active"
                }
              </div>
            </div>
          }
          <div
            style={{
              fontSize: '75%',
              color: '#555',
              display: 'flex',
              alignItems: 'flex-end',
              // flexDirection: 'column',
              justifyContent: 'space-between',
              fontStyle: 'normal'
            }}
          >
            <div
              style={{
                fontWeight: '600',
                fontSize: '90%'
              }}
            >
              {distanceInWords(order.created, new Date())} ago
            </div>
            {format(order.created, "M/D/YY \n HH:mm ")}
          </div>
        </div>


      </ConditionalLink>
    </div>
    )]
  }
}
