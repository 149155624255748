import React from 'react';
import { Redirect, Link } from 'react-router-dom'
import Loading from '../components/common/loading'

export default class Login extends React.Component {
  state = {
    username: "",
    password: "",
    authorized: null,
    loading: false,
    message: "",
  }
  submit = () => {
    this.setState({ loading: true, message: "" })
    fetch('/api/v1/authorize', {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: this.state.username,
        password: this.state.password,
      })
    }).then(response => response.json())
    .then(data => {
      this.setState({ loading: false })
      if (data && data._id) {
        localStorage.setItem('token', data._id);
        this.setState({ authorized: true })
      } else {
        this.setState({ message: "Error Occurred" })
      }
    })
  }
  render() {
    const { authorized, username, password, message, loading } = this.state;
    if (authorized === true) {
      return <Redirect to="/" />
    }
    return (
      <div>
        <Link
          to="/dashboard"
          style={{
            padding: '10px',
            // margin: '10px',
            backgroundColor: "#333",
            border: "solid grey 1px",
            display : "flex",
            justifyContent: 'center',
            fontSize: "120%",
            fontWeight: '800',
            // cursor: 'pointer',
            color: "white"
          }}
      >
        <i className="material-icons">arrow_back</i>Dashboard
      </Link>
        <div style={{ display: 'flex', justifyContent: 'center'}}>
          <div
            className="app-item"
            style={{
              marginTop: '200px',
              padding: '40px',
              borderRadius: '3px',
              border: 'solid rgba(0,0,0,0.1) 1px'
              // maxWidth: '420px',
            }}
          >
            <h2 style={{ display: 'flex', justifyContent: 'center'}}>Login</h2>
            {message && 
              <div
                style={{
                  color: 'red',
                  fontWeight: '600'
                }}
              >
                {message}
              </div>
            }
            <input
              placeholder="username"
              value={username}
              onChange={e => this.setState({ username: e.target.value })}
            />
            <input
            type="password"
            placeholder="password"
              value={password}
              onChange={e => this.setState({ password: e.target.value })}
              onKeyPress={e=> e.key  === 'Enter' && this.submit()}
            />
            {loading && Loading }
            <button
              onClick={() => this.submit()}
              style={{
                padding: '10px 200px',
                backgroundColor: '#324b6f',
                fontWeight: '800',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '1px 1px 2px 2px rgba(0,0,0,0.2)',
                color: 'white',
                // border: 'none',
                borderRadius: '2px',
                marginTop: '10px',
                width: '100%',
              }}
            >
              Submit
            </button>
          </div>
            
        </div>  
      </div>
    )
  }
}