import React from 'react';
import { Link, Route } from 'react-router-dom'
import Modal from './common/modal';
import Swal from 'sweetalert2';
import ReactTooltip from 'react-tooltip'



function urlBase64ToUint8Array(base64String) {
	const padding = '='.repeat((4 - base64String.length % 4) % 4);
	const base64 = (base64String + padding)
	.replace(/\-/g, '+')
	.replace(/_/g, '/')
	;
	const rawData = window.atob(base64);
	return Uint8Array.from([...rawData].map((char) => char.charCodeAt(0)));
}
const CheckInAdminView  = ({
  checkIns,
  user,
  toggleCheckIn,
  fetchCheckIns
}) => {
  return (
    <div
      style={{
      }}
    >
      <Route
        path="/checkins/manage"
        render={({ history }) => (
          <Modal
            close={() => history.goBack()}
          >
            <ManageCheckins
              history={history}
              fetchCheckIns={fetchCheckIns}
              checkIns={checkIns}
              user={user}
            />
          </Modal>
        )}
      />
    <div
      className="app-item"
      style={{ position: 'relative', padding: '0px' }}
    >
      <div
        className="grid-x "
      // style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}
      >
        <Link
          style={{
            display: 'flex',
            // flexDirection: 'column',
            alignItems: 'center',
            // justifyContent: 'space-between',
            fontSize: '100%',
            fontWeight: '800',
            backgroundColor: '#333',
            color: "white",
            // paddingLeft: '20px',
            justifyContent: 'center',
            borderRadius: '3px 3px 3px 3px',
            padding: '10px'
          }}
          className="cell large-2 medium-2 small-12"
          to={user.role === 'admin' ? "/checkins/manage" : ''}
        >
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <i className="material-icons">directions_walk</i>
            <span>Check Ins</span>
          </div>
        <div>
        </div>
      </Link>
      <div
        
        className="cell large-10 medium-10 small-12"
      >
        <div
          style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', fontSize: '90%', padding: '10px' }}
        >
          {checkIns.map((checkIn, i) => (
            <div
              key={checkIn._id}
              style={{
                margin: '0px 0px',
              }}
              data-tip
              data-for={checkIn.label + i}
            >
              <ReactTooltip
                id={checkIn.label+i} 
                // type='info' 
                effect='solid'
                place='bottom'
              >
                {checkIn.phoneNumber ? (
                  <span>{checkIn.phoneNumber}</span>
                ) : (
                  <span>No Phone Number</span>
                )}
              </ReactTooltip>
              <div
                style={{
                  padding: '0px 5px',
                  marginBottom: '5px'
                }}
              >
                <div
                  style={{ padding: '5px 0px' }}
                >
                  {checkIn.label}
                </div>
                <input
                  className="tgl tgl-light"
                  id={`cb${checkIn._id}`}
                  type="checkbox"
                  checked={checkIn.active}
                  onChange={() => {
                    if (user.role === 'admin') {
                      toggleCheckIn(checkIn._id, !checkIn.active);
                    } else {
                      console.log("not admin")
                    }
                  }}
                />
                <label
                  className="tgl-btn"
                  htmlFor={`cb${checkIn._id}`}
                />
              </div>
            </div>
          ))}

        </div>
      </div>
    </div>
        {user && user.role === "admin" && 
          <Link
            to="/checkins/manage"
            style={{
              position : "absolute",
              top: '5px',
              right: '5px',
              color: 'grey',
              fontSize: '85%',
              cursor: 'pointer',
            }}
          >
            <i className="material-icons">edit</i>
          </Link>
        }
      </div>
     
    </div>
  )
}
class CheckInDashView extends React.Component {
  render() {
    const { checkIns } = this.props;
    const orderedCheckins = [...checkIns.filter(c=> c.active), ...checkIns.filter(c=>!c.active)]

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
        }}
      >
        {orderedCheckins.map((checkIn, i) => (
          <div
            key={checkIn._id}
            style={{
              marginLeft: '5px',
              marginRight: '8px',
              marginBottom: '5px',
              color: checkIn.active ? '#37d254':'rgb(113, 82, 82)',
              fontSize: '100%',
              fontWeight: '800',
              display: 'flex',
              alignItems: 'center'
            }}
            data-tip
            data-for={checkIn.label + i}
          >
            <ReactTooltip
              id={checkIn.label+i} 
              // type='info' 
              effect='solid'
              place='bottom'
            >
              {checkIn.phoneNumber ? (
                <span>{checkIn.phoneNumber}</span>
              ) : (
                <span>No Phone Number</span>
              )}
            </ReactTooltip>
            <i
              className="material-icons"
              style={{
                fontSize: '125%',
              }}
            >
              {checkIn.active ? "phone" : "phonelink_erase"}
            </i>
            {checkIn.label}	
          </div>
        ))}
      </div>
    )
  }
}
export default class CheckInContainer extends React.Component {
  state = {
    checkIns: [],
	}

  componentDidMount() {
    this.fetchCheckIns()

    this.props.socket.on("checkin_update", params => {
      this.updateCheckin(params)
	  })
	  this.props.socket.on("checkin_reset", params => {
		  this.fetchCheckIns();
	  })

	}
  componentWillUnmount() {
	this.props.socket.removeListener("checkin_update");
	this.props.socket.removeListener("checkin_reset");

	}

  fetchCheckIns = () => {
    fetch('/api/v1/checkIns')
      .then(response => response.json())
      .then(data => {
        if (data) {
          this.setState({ checkIns: data });
        } else {
          console.log("Error pulling check ins")
        }
      })
	}
  toggleCheckIn = (id, state) => {
    fetch('/api/v1/checkIns', {
      method: "PUT",
      headers: {
        'Content-Type' : "application/json",
        'accept': 'application/json',
      },
      body: JSON.stringify({
        _id: id,
        changes: {
          active: state,
        }
      })
    }).then(response => response.json())
    .then(data => {
      // this.fetchCheckIns();
    })
	}
  updateCheckin(updatedCheckin) {
    const { checkIns } = this.state;
    const newCheckins = checkIns.map(checkIn => 
      checkIn._id === updatedCheckin._id ? updatedCheckin : checkIn
    )
    this.setState({ checkIns : newCheckins });
  }
  render() {
    const { checkIns } = this.state;
    const { user, view } = this.props;
    if (view === "admin") {
      return (
        <CheckInAdminView
          checkIns={checkIns}
          user={user}
          toggleCheckIn={this.toggleCheckIn}
          fetchCheckIns={this.fetchCheckIns}
          updateCheckin={this.updateCheckin}
        />
      )
    } else {
      return (
        <CheckInDashView
          checkIns={checkIns}
          user={user}
        />
      )
    }
    
    
  }
}


class ManageCheckins extends React.Component {
	state = {
		newFormActive: false,
	}
	changeCheckinState = newState => this.setState(newState)

  createCheckin = ( {phoneNumber, label}, cb ) => {
    if (!label) {
      alert("Missing Label Name")
    } else {
      fetch('/api/v1/checkIns', {
        method: "POST",
        headers: {
          'Content-Type' : "application/json",
          'accept': 'application/json',
        },
        body: JSON.stringify({
          pkg: {
            label,
            phoneNumber
          }
        })
      }).then(response => response.json())
      .then(data => {
        this.props.fetchCheckIns();
        Swal.fire({
          position: 'top-end',
          type: 'success',
          title: 'Check in Successfully Made',
          showConfirmButton: false,
          timer: 999
				})
				cb && cb();
      })
    }
	}
	registerForNotifications = (checkIn) => {
		const { user } = this.props;

  
	if (navigator.serviceWorker) {
		
		navigator.serviceWorker.register('/serviceWorker.js')
		.then(response => console.log(response))
	
		navigator.serviceWorker.ready
		.then(function(registration) {
		return registration.pushManager.getSubscription()
		.then(async function(subscription) {
			if (subscription) {
			return subscription;
			}
			const response = await fetch('/vapidPublicKey');
			const vapidPublicKey = await response.text();
			const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);
			return registration.pushManager.subscribe({
			userVisibleOnly: true,
			applicationServerKey: convertedVapidKey
			});
		});
		}).then(function(subscription) {

		fetch('/register-notifications', {
			method: 'post',
			headers: {
				'Content-type': 'application/json'
			},
			body: JSON.stringify({
				subscription: subscription,
				checkIn: checkIn,
				user: user,
			}),
		});


	});
  
  }
		
	}
	modifyCheckin = (id, changes) => {
				fetch('/api/v1/checkIns', {
					method: "PUT",
					headers: {
						'Content-Type' : "application/json",
						'accept': 'application/json',
					},
					body: JSON.stringify({
						_id: id,
						changes,
					})
				}).then(response => response.json())
				.then(data => {
					this.props.fetchCheckIns();
					Swal.fire('Success!');
				})
	}

	removeCheckin = (id) => {
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#324b6f',
			cancelButtonColor: 'maroon',
			confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
			if (result.value) {
				fetch('/api/v1/checkIns', {
					method: "DELETE",
					headers: {
						'Content-Type': 'application/json',
						'accept': 'application/json'
					},
					body: JSON.stringify({
						_id: id
					})
				}).then(response => response.json())
					.then(data => {
						this.props.fetchCheckIns();
						Swal.fire(
							'Deleted!',
							'Your file has been deleted.',
							'success'
						)
					})
			}
		})
	}

  render() {
		const { checkIns } = this.props;
		const { newFormActive } = this.state;

    return (
			<div style={{ paddingTop: "20px" }} >
				<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
					<div style={{ fontSize: "150%", fontWeight: '600' }}>Manage Check Ins</div>
					
					{!newFormActive && 
						<div style={{ display: 'flex' }}>
							<div
								className="button"
								onClick={() => this.setState({ newFormActive: true })}
							>
								+ Add New Check-In
							</div>
						</div>
					}
				</div>

				{newFormActive && 
						<CheckInForm
							createCheckin={this.createCheckin}
							changeCheckinState={this.changeCheckinState}
						/>
				}


				<label><b>Check-ins</b></label>
				{checkIns.map(checkIn => (
					<div
						key={checkIn._id}
					>	
						<CheckInModify
							modifyCheckin={this.modifyCheckin}
							removeCheckin={this.removeCheckin}
							checkIn={checkIn}
							registerForNotifications={this.registerForNotifications}
						/>
					</div>
				))}
      </div>
    )
  }
}

class CheckInModify extends React.Component {
	state = {
    label: "",
    phoneNumber: "",
  }
  
	componentDidMount() {
		this.setState({
      label: this.props.checkIn.label,
      phoneNumber: this.props.checkIn.phoneNumber
		})
  }
  
	render() {
		const hasChanges = this.props.checkIn.label !== this.state.label || this.props.checkIn.phoneNumber !== this.state.phoneNumber;
		return (
			<div style={{ display: 'flex', alignItems: 'center'  }} >

        {/* <div> */}
          {/* <label>Label</label> */}
          <input
            style={{
              marginRight: '10px'
            }}
            value={this.state.label}
            onChange={e => this.setState({ label: e.target.value })}
          />
          {/* <label>Phone Number</label> */}
          <input
            placeholder="Ex: 103"
            value={this.state.phoneNumber}
            onChange={e => this.setState({ phoneNumber: e.target.value })}
          />
        {/* </div> */}
				<div
				  className="button"
				  style={{
					margin: "0px 10px",
					padding: '4px',
          lineHeight: '100%',
          
				  }}
				  onClick={(ev) => this.props.registerForNotifications(this.props.checkIn)}
				>
					Register for Notifications
				</div>
				{hasChanges ?
						<div
              className="button"
              style={{
                backgroundColor: 'forestgreen',
                margin: '0px 10px 0px 5px'
              }}
							onClick={() => 
								this.props.modifyCheckin(
									this.props.checkIn._id,
									{
                    label: this.state.label,
                    phoneNumber: this.state.phoneNumber
                  }
								)}
						>
						  Submit
						</div>
						:
					<span
							onClick={() => this.props.removeCheckin(this.props.checkIn._id)}
							style={{
							fontSize: '150%',
							color: 'grey',
							fontWeight: '800',
							marginLeft: '10px',
							marginRight: '10px',
							cursor: 'pointer',
						}}
					>
						&times;
					</span>
				}
			</div>
		)
	}
}


class CheckInForm extends React.Component {
	state = {
    label: '',
    phoneNumber: '',
	}

	render() {
		const { label, phoneNumber } = this.state;
		const { createCheckin, changeCheckinState } = this.props;

		return (
			<div style={{ marginBottom: '20px' }} >
				<div style={{ display: 'flex', alignItems: 'flex-end', height: '100%'}}>
          <div style={{  }}>
            <label>New Checkin Name</label>
            <input
              placeholder="Ex: SH_OFC"
              value={label}
              style={{
                marginBottom: '0px'
              }}
              onChange={e => this.setState({ label: e.target.value })}
            />
          </div>
          <div>
            <label>Phone Number</label>
            <input
              placeholder="Ex: 103"
              style={{
                marginBottom: '0px'
              }}
              value={phoneNumber}
              onChange={e => this.setState({ phoneNumber: e.target.value })}
            />
          </div>
          <div
            className="button"
            style={{
              margin: '0px'
            }}
						onClick={() => createCheckin({
              label,
              phoneNumber
            }, () => {
							changeCheckinState({ newFormActive: false });
						})}
          >
            Submit
          </div>
				</div>
			</div>
		)
	}
}
