import React from 'react';
import { Link, Route } from 'react-router-dom';
import Modal from './common/modal';
import Swal from 'sweetalert2'
import { format } from 'date-fns'


export default class UsersList extends React.Component {
  state = {
    users: [],
  }
  componentDidMount() {
    this.fetchUsers()
  }
  fetchUsers = () => {
    fetch('/api/v1/users')
      .then(response => {
        return response.json()
      }).then(data => {
        if (data && !data.err) {
          this.setState({ users: data })
        }
      })
  }
  updateUsers = (newUser) => {
    const { users } = this.state;
    const updatedUsers = users.map(user => user._id === newUser._id ? newUser : user);
    this.setState({ users: updatedUsers });
  }
  removeUser = (newUser) => {
    const { users } = this.state;
    const updatedUsers = users.filter(user => user._id !== newUser._id);
    this.setState({ users: updatedUsers });
  }
  render() {
    const { users } = this.state;
    return (
      <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '30px'}}>
        <div
          className="app-item"
          style={{
            maxWidth: '1000px',
            minWidth: '800px'
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between'}}>
            <h2>Users</h2>
            <Link
              to="/users/new"
              style={{
                padding: '10px',
                borderRadius: '2px',
                backgroundColor: 'forestgreen',
                boxShadow: '1px 2px 1px 1px rgba(0,0,0,0.2)',
                cursor: 'pointer',
                height: 'min-content',
                color: 'white',
                fontWeight: '800'
              }}
            >
              New User
            </Link>
          </div>
          <Route
            path="/users/new"
            exact
            render={({ history }) => {
              return (
                <UserForm
                  fetchUsers={this.fetchUsers}
                  history={history}
                />
              )
            }}
          />
          <Route
            path="/users/:userId/edit"
            render={({ history, match }) => {
              return (
                <UserForm
                  fetchUsers={this.fetchUsers}
                  history={history}
                  user={users.find(user => user._id === match.params.userId)}
                  updateUsers={this.updateUsers}
                  removeUser={this.removeUser}
                />
              )
            }}
          />
          <div>
            <div style={{ display: 'flex', flexWrap: 'nowrap'}}>
              <div style={{ width: '33%', border: 'solid rgba(255,255,255,0.4) 1px', padding: '6px', fontWeight: '800', color: "white", backgroundColor: "#333" }}>User</div>
              <div style={{ width: '33%', border: 'solid rgba(255,255,255,0.4) 1px', padding: '6px', fontWeight: '800', color: "white", backgroundColor: "#333" }}>Role</div>
              <div style={{ width: '33%', border: 'solid rgba(255,255,255,0.4) 1px', padding: '6px', fontWeight: '800', color: "white", backgroundColor: "#333" }}>Created</div>
            </div>
            {users.map(user => {
              return (
              <div style={{ display: 'flex', flexWrap: 'nowrap'}} key={user._id}>
                <Link
                  style={{
                    width: '33%',
                    border: 'solid rgba(0,0,0,0.2) 0.5px',
                    padding: '6px',
                    color: '#333',
                    fontWeight: '600'
                  }}
                  to={`/users/${user._id}/edit`}
                >
                  {user.username}
                </Link>
                <div style={{ width: '33%', border: 'solid rgba(0,0,0,0.2) 0.5px', padding: '6px' }}>{user.role}</div>
                <div style={{ width: '33%', border: 'solid rgba(0,0,0,0.2) 0.5px', padding: '6px' }}>{format(user.created, 'MMMM DD, YYYY')}</div>
              </div>
              )
            })}
          </div>

        </div>
        
      </div>
    )
  }
}


class UserForm extends React.Component {
  state = {
    username: "",
    role: false,
    password: "",
    confPass: "",
    errors: {},
    resetPassActive: false,
  }
  componentDidMount() {
    const { user } = this.props;
    if (user) {
      this.setState({
        username: user.username,
        role: user.role === "admin",
        // password: user.password,
        // confPass: user.password,
      })
    } else {
      this.setState({ resetPassActive: true })
    }
  }
  submit = () => {
    if (this.state.password !== this.state.confPass) {
      alert("Password's do not match")
      return;
    }

    if (this.props.user) {
      fetch('/api/v1/users', {
        method: "PUT",
        headers: {
          'Content-Type': 'application/json',
          'Accepts': 'application/json',
        },
        body: JSON.stringify({
          _id: this.props.user._id,
          changes: {
            username: this.state.username,
            role: this.state.role ? "admin" : "",
            password: this.state.password,
          }
        })
      }).then(response => response.json())
      .then(data => {
        if (data.err) {
          alert("Error Occurred");
        } else {
          Swal.fire({
            position: 'top-end',
            type: 'success',
            title: 'User Successfully Modified',
            showConfirmButton: false,
            timer: 1000
          })
          console.log(data)
          this.props.updateUsers(data)
          this.props.history.replace("/users")
        }
      })
    } else {
      
      fetch('/api/v1/users', {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Accepts': 'application/json',
        },
        body: JSON.stringify({
          pkg: {
            username: this.state.username,
            role: this.state.role ? 'admin' : undefined,
            password: this.state.password,
          }
        })
      }).then(response => response.json())
      .then(data => {
                
        if (data.err) {
          this.setState({ errors: data.err })
        } else {
          this.props.history.goBack()
          this.props.fetchUsers();
          Swal.fire({
            position: 'top-end',
            type: 'success',
            title: 'Order Successfully Created',
            showConfirmButton: false,
            timer: 1000
          })
        }
      })
    }
  }

  removeUser() {
    const { user } = this.props;
    if (!user) {
      alert("Should not be here")
    }
    fetch('/api/v1/users', {
      method: "DELETE",
      headers: {
        'Content-Type': 'application/json',
        'Accepts': 'application/json',
      },
      body: JSON.stringify({
        _id: user._id
      })
    }).then(res => res.json())
    .then(data => {
      if (data && data._id) {
        this.props.removeUser(data)
        this.props.history.replace("/users")
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
      }
    })
  }

  render() {
    const { user } = this.props;
    const { resetPassActive } = this.state;

    const inputProps = ({ internal: field, external: label, type, placeholder }) => ({
      onChange: e => this.setState({ [field]: e.target.value }),
      value: this.state[field],
      label: label,
      error: this.state.errors[field],
      type,
      placeholder,
    });
    const toggleProps = ({ internal: field, external: label, id, note }) => ({
			label,
      onChange: e => this.setState({ [field]: !this.state[field] }),
			value: this.state[field],
			error: this.state.errors[field],
      id,
      note,
		})
    return (
      <Modal
        close={() => this.props.history.goBack()}
      >
        <h2>{user ? "Edit User" : "New User" }</h2>
        <TextInput {...inputProps({
          internal: "username",
          external: "Username"
        })} />

        {resetPassActive ?
          <div>
            <TextInput {...inputProps({
              internal: "password",
              external: "Password",
              type:"password"
            })} />
            <TextInput {...inputProps({
              internal: "confPass",
              external: "Confirm Password",
              type:"password",
            })} />
          </div>
          :
          <div
            onClick={() => this.setState({ resetPassActive: !resetPassActive })}
            style={{
              color: '#324b6f',
              cursor: 'pointer'
            }}
          >
            Reset Password
          </div>
        }
        <ToggleInput {...toggleProps({
          internal: "role",
          external: "Admin Rights",
          // note: '',
          id: 'admin'
        })} />
        <div
          style={{
            padding: '10px',
            borderRadius: '2px',
            backgroundColor: '#324b6f',
            boxShadow: '1px 2px 1px 1px rgba(0,0,0,0.2)',
            cursor: 'pointer',
            height: 'min-content',
            color: 'white',
            fontWeight: '800',
            display: 'flex',
            justifyContent: 'center'
          }}
          onClick={() => this.submit()}
        >
          Submit
        </div>
        <div
          style={{
            padding: '10px',
            borderRadius: '2px',
            backgroundColor: 'maroon',
            boxShadow: '1px 2px 1px 1px rgba(0,0,0,0.2)',
            cursor: 'pointer',
            height: 'min-content',
            color: 'white',
            fontWeight: '800',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '10px'
          }}
          onClick={() => {
            Swal.fire({
              title: "Are you sure?",
              text: "You won't be able to revert this!",
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
              if (result.value) {
                this.removeUser()
              }
            })
          }}
        >
          Remove User Permanently
        </div>
      </Modal>
    )
  }
}
const TextInput = ({
  label,
  onChange,
  placeholder,
  value,
	error,
  type,
  note,
}) => {
  return (
    <div>
      <div
        style={{ display: 'flex', justifyContent: 'space-between'}}
      >
        <label>{label}</label>
        {error && 
          <div
            style={{ color: 'red' }}
          >
            {error}
          </div>
        }
        {note && 
          <div><em>{note}</em></div>
        }
			</div>
			{type === "textarea" ? (

				<textarea
					rows="6"
					value={value}
					placeholder={placeholder}
					type={type}
					onChange={onChange}
				/>
			):(

				<input
					value={value}
					onChange={onChange}
					placeholder={placeholder}
					type={type}
				/>
			)}
    </div>
  )
}
const ToggleInput = ({
	label,
	onChange,
	value,
	error,
  id,
  note,
}) => (
	<div
		style={{
			padding: '10px 0px',
			marginBottom: '10px'
		}}
	>
		<div
			style={{ padding: '5px 0px' }}
		>
			{label}
		</div>
		<input
			className="tgl tgl-light"
			id={`cb${id}`}
			type="checkbox"
			checked={value}
			onChange={onChange}
		/>
		<label
			className="tgl-btn"
			htmlFor={`cb${id}`}
		/>
    {note && 
      <div style={{ fontSize: '90%', marginBottom: '10px', marginTop: '10px' }}><em>{note}</em></div>
    }
	</div>

)
