import React from 'react';
import Swal from 'sweetalert2';

export default class AnnouncementForm extends React.Component {
  state = {
    announcementText:"",
    confirmationToggle: "",

  
  }

  createAnnouncement = () => {
    const { confirmationToggle, announcementText } = this.state;
    fetch('/api/v1/posts', {
      method: "POST",
      headers: {
        'Content-Type' : "application/json",
        'accept': 'application/json',
      },
      body: JSON.stringify({
        pkg: {
          body: announcementText,
          confirmationRequired: confirmationToggle
        }
      })
    }).then(response => response.json())
    .then(data => {
      console.log(data);
      Swal.fire({
        position: 'top-end',
        type: 'success',
        title: 'Announcement Successfully Made',
        showConfirmButton: false,
        timer: 999
      })
      this.setState({ announcementText: "", confirmationToggle: false })
    })
  }

  render() {
    const { confirmationToggle, announcementText } = this.state;
    return (
      <div
        // className="app-item"
        style={{
          color: "#333",
          fontWeight: '800',
          padding: '0px'
          // display: 'flex',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: "#333",
            color: 'white',
            // padding: '10px',
            // borderRadius: '2px'
            // flexDirection: 'column',
            // alignItems: 'center'
          }}
          className="app-item"
        >
          <i className="material-icons">announcement</i>
          New Announcement
        </div>
        <div
          className="app-item"
          style={{
            // display: 'flex'
            padding: '10px',
            marginBottom: '15px'
          }}
        >
          <div
            style={{
              fontWeight: '400'
            }}
          >
            Post an Announcement
          </div>
          <textarea
            rows="2"
            placeholder="New Announcement Here"
            value={announcementText}
            onChange={e => this.setState({ announcementText: e.target.value })}
          />
          <div
            style={{
              padding: '10px 0px 5px 0px',
              display: 'flex'
            }}
          >
            <div
              style={{
                fontWeight: '400'
              }}
            >
              Require Confirmation
            </div>
            <input
              className="tgl tgl-light"
              id="announcement-toggle"
              type="checkbox"
              checked={confirmationToggle}
              onChange={e => this.setState({ confirmationToggle: e.target.checked})}
            />
            <label
              className="tgl-btn"
              htmlFor="announcement-toggle"
            />
          </div>
          <div
            style={{
              display: 'flex'
            }}
          >
            <div
              className="button"
              style={{
                margin: '8px 0px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '100px'
              }}
              onClick={() => this.createAnnouncement()}
            >
              Post
            </div>
          </div>
        </div>
      </div>
    )
  }
}